import React, { useState } from "react";
import {
    Box,
    Flex,
    Button,
    Input,
    Divider,
    Heading,
    Stack,
    useToast,
    useColorMode,
    useTheme,
    InputGroup,
    InputRightElement,
} from "@chakra-ui/react";
import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons';
import { useNavigate } from "react-router-dom";

function LoginForm() {
    const { colorMode } = useColorMode();
    const theme = useTheme();
    const toast = useToast();
    const navigate = useNavigate();

    const [identifiant, setIdentifiant] = useState("");
    const [password, setPassword] = useState("");
    const [showPassword, setShowPassword] = useState(false); // Nouvel état pour gérer la visibilité du mot de passe

    // Fonction pour basculer la visibilité du mot de passe
    const togglePassword = () => setShowPassword(!showPassword);

    const handleSubmit = () => {
        if (identifiant === "@JServices#Rep@s89000" && password === "@JServices#Rep@s/JA.users") {
            localStorage.setItem("isAuthenticated", "true");
            navigate("/ScanRepas");
            toast({
                title: "Connexion réussie",
                status: "success",
                duration: 5000,
                isClosable: true,
            });
        } else {
            toast({
                title: "Erreur de connexion",
                description: "Identifiant ou mot de passe incorrect.",
                status: "error",
                duration: 5000,
                isClosable: true,
            });
        }
    };

    return (
        <Flex align="center" justify="center">
            <Box
                w={{ base: "90vw", lg: "400px" }}
                p={6}
                bg="white"
                borderRadius="lg"
                boxShadow="lg"
                textAlign="center"
            >
                <Heading
                    mb={6}
                    fontSize="2xl"
                    color="teal.700"
                >
                    Se connecter
                </Heading>

                <Flex align="center" mb={6}>
                    <Divider />
                </Flex>

                <Stack spacing={4} mb={6}>
                    <Input
                        bg="blue.50"
                        placeholder="Identifiant"
                        type="text"
                        value={identifiant}
                        onChange={(e) => setIdentifiant(e.target.value)}
                        focusBorderColor="blue.500"
                        size="lg"
                        sx={{
                            "::placeholder": {
                                color: colorMode === "dark" ? "white" : "black",
                            },
                            color: colorMode === "dark" ? "white" : "black",
                        }}
                    />
                    <InputGroup size="lg">
                        <Input
                            bg="blue.50"
                            placeholder="Mot de passe"
                            type={showPassword ? "text" : "password"}
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            focusBorderColor="blue.500"
                            sx={{
                                "::placeholder": {
                                    color: colorMode === "dark" ? "white" : "black",
                                },
                                color: colorMode === "dark" ? "white" : "black",
                            }}
                        />
                        <InputRightElement width="4.5rem">
                            <Button
                                h="1.75rem"
                                size="sm"
                                onClick={togglePassword}
                                bg="transparent"
                                _hover={{ bg: "transparent" }}
                            >
                                {showPassword ? <ViewOffIcon /> : <ViewIcon />}
                            </Button>
                        </InputRightElement>
                    </InputGroup>
                </Stack>

                <Button
                    colorScheme="green"
                    size="lg"
                    w="100%"
                    mb={6}
                    onClick={handleSubmit}
                    transition="300ms"
                    _hover={{
                        bg: colorMode === "dark" ? "blue.100" : "blue.600",
                    }}
                >
                    Continue
                </Button>
            </Box>
        </Flex>
    );
}

export default LoginForm;