import React, { useState, useEffect, useMemo } from 'react';
import {
    Box,
    Button,
    Flex,
    Heading,
    Text,
    IconButton,
    Input,
    useToast,
    Badge,
    Collapse,
    VStack,
    HStack,
    Divider,
    Tooltip,
    Card,
    CardHeader,
    CardBody,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Select,
    FormControl,
    FormLabel,
    useDisclosure,
    SimpleGrid
} from '@chakra-ui/react';
import {
    AddIcon,
    CloseIcon,
    ChevronDownIcon,
    ChevronUpIcon,
    CalendarIcon,
    EditIcon,
    DeleteIcon,
    CheckIcon,
    RepeatIcon
} from '@chakra-ui/icons';
import { useTableCount } from '../../contexts/TableCountContext';

// Fonction pour résumer les repas par date
const summarizeMealsByDate = (tableData) => {
    if (!tableData || !Array.isArray(tableData) || tableData.length === 0) {
        console.warn('Données de tableau invalides ou vides');
        return {};
    }

    const summaryByDate = {};

    try {
        tableData.forEach(table => {
            if (!table || table.length < 2) return;

            // Récupérer les dates dans la première ligne (maintenant juste les jours)
            const dateHeaders = table[0];
            const dates = dateHeaders.filter((_, index) => index >= 2 && index % 2 === 0);

            // Parcourir chaque ligne à partir de la deuxième ligne
            for (let rowIndex = 1; rowIndex < table.length; rowIndex++) {
                const row = table[rowIndex];
                if (!row || row.length < 2) continue;

                const category = row[0];       // Type (ENTREE, PLAT PRINCIPAL, etc.)

                // Traiter chaque paire de colonnes (plat + sélection/quantité)
                for (let i = 1; i < row.length; i += 2) {
                    const dateIndex = Math.floor((i - 1) / 2);
                    const date = dates[dateIndex];
                    if (!date) continue;

                    const mealName = row[i];
                    const selectionCell = row[i + 1];

                    // Vérifier les marques de sélection (X ou SELECTED)
                    const isSelected = (
                        selectionCell === 'X' ||
                        selectionCell === 'SELECTED,' ||
                        !isNaN(parseFloat(selectionCell))
                    );

                    const quantity = isSelected ? (
                        isNaN(parseFloat(selectionCell)) ? 1 : parseFloat(selectionCell)
                    ) : 0;

                    if (quantity > 0) {
                        if (!summaryByDate[date]) {
                            summaryByDate[date] = [];
                        }

                        summaryByDate[date].push({
                            meal: `${category} - ${mealName}`.toLowerCase(),
                            quantity,
                        });
                    }
                }
            }
        });

        console.log('Résumé des repas:', summaryByDate);

    } catch (error) {
        console.error('Erreur lors du traitement des données:', error);
        return {};
    }

    return summaryByDate;
};

const MealSummary = ({ data, onValidate }) => {
    const initialSummary = React.useMemo(() => {
        return data && Array.isArray(data) ? summarizeMealsByDate(data) : {};
    }, [data]);

    const [mealSummaryByDate, setMealSummaryByDate] = useState(initialSummary);
    const [history, setHistory] = useState([]);
    const [expandedDates, setExpandedDates] = useState({});
    const [editMode, setEditMode] = useState({});
    const toast = useToast();
    const { incrementTableCount, decrementTableCount } = useTableCount();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [newMeal, setNewMeal] = useState({
        type: '',
        name: '',
        day: '',
        quantity: 1
    });

    // Types de repas disponibles
    const mealTypes = [
        'ENTREE',
        'PLAT PRINCIPAL',
        'ACCOMPAGNEMENT',
        'FROMAGE',
        'DESSERT',
        'DINER'
    ];

    // Fonction pour ajouter un nouveau repas
    const handleAddMeal = () => {
        if (!newMeal.type || !newMeal.name || !newMeal.day || newMeal.quantity < 1) {
            toast({
                title: "Erreur",
                description: "Veuillez remplir tous les champs",
                status: "error",
                duration: 3000,
                isClosable: true,
            });
            return;
        }

        const updatedSummary = { ...mealSummaryByDate };
        const fullMealName = `${newMeal.type} - ${newMeal.name}`.toLowerCase();

        if (!updatedSummary[newMeal.day]) {
            updatedSummary[newMeal.day] = [];
        }

        updatedSummary[newMeal.day].push({
            meal: fullMealName,
            quantity: parseFloat(newMeal.quantity)
        });

        setMealSummaryByDate(updatedSummary);
        onClose();
        setNewMeal({ type: '', name: '', day: '', quantity: 1 });

        toast({
            title: "Succès",
            description: "Le repas a été ajouté",
            status: "success",
            duration: 3000,
            isClosable: true,
        });
    };

    useEffect(() => {
        if (data && Array.isArray(data)) {
            setMealSummaryByDate(summarizeMealsByDate(data));
        }
    }, [data]);

    const handleMealValidation = (meals) => {
        setHistory([...history, meals]);
        onValidate(meals);
        incrementTableCount();

        toast({
            title: 'Ajout réussi',
            description: 'Les repas ont été ajoutés.',
            status: 'success',
            duration: 3000,
            isClosable: true,
        });
    };

    const handleUndoLastAction = () => {
        if (history.length > 0) {
            const newHistory = [...history];
            const lastEntry = newHistory.pop();

            setHistory(newHistory);
            decrementTableCount();

            const updatedMeals = {};
            Object.keys(lastEntry).forEach((date) => {
                if (!updatedMeals[date]) {
                    updatedMeals[date] = [];
                }
                lastEntry[date].forEach((meal) => {
                    updatedMeals[date].push({
                        meal: meal.meal,
                        quantity: -meal.quantity,
                    });
                });
            });

            onValidate(updatedMeals);

            toast({
                title: 'Annulation réussie',
                description: 'Les repas ont été retirés.',
                status: 'success',
                duration: 3000,
                isClosable: true,
            });
        }
    };

    const handleRemoveMeal = (date, mealIndex) => {
        const updatedSummary = { ...mealSummaryByDate };
        updatedSummary[date].splice(mealIndex, 1);

        if (updatedSummary[date].length === 0) {
            delete updatedSummary[date];
        }

        setMealSummaryByDate(updatedSummary);
    };

    const handleRemoveDate = (date) => {
        const updatedSummary = { ...mealSummaryByDate };
        delete updatedSummary[date];
        setMealSummaryByDate(updatedSummary);
    };

    const handleQuantityChange = (date, mealIndex, newQuantity) => {
        const updatedSummary = { ...mealSummaryByDate };
        const parsedQuantity = parseFloat(newQuantity);
        if (!isNaN(parsedQuantity) && parsedQuantity >= 0) {
            updatedSummary[date][mealIndex].quantity = parsedQuantity;
            setMealSummaryByDate(updatedSummary);
        }
    };

    const toggleDateExpansion = (date) => {
        setExpandedDates(prev => ({
            ...prev,
            [date]: !prev[date]
        }));
    };

    const toggleEditMode = (date) => {
        setEditMode(prev => ({
            ...prev,
            [date]: !prev[date]
        }));
    };

    // Ajouter cette fonction dans le composant
    const getTotalMealsForDate = (date) => {
        return mealSummaryByDate[date].reduce((total, item) => {
            return total + item.quantity;
        }, 0);
    };

    const getMealTypeColor = (mealType) => {
        const type = mealType.split(' - ')[0];
        switch (type) {
            case 'déjeuner': return 'blue';
            case 'diner': return 'purple';
            default: return 'gray';
        }
    };

    const getDayOrder = (date) => {
        const dayOrder = {
            'lundi': 1,
            'mardi': 2,
            'mercredi': 3,
            'jeudi': 4,
            'vendredi': 5,
            'samedi': 6,
            'dimanche': 7
        };

        const day = date.split(' ')[0].toLowerCase();
        return dayOrder[day] || 8;
    };

    const sortedDates = useMemo(() => {
        return Object.keys(mealSummaryByDate).sort((a, b) => getDayOrder(a) - getDayOrder(b));
    }, [mealSummaryByDate]);


    return (
        <Box
            bg="white"
            p={2}
            borderRadius="xl"
            boxShadow="xl"
            mt={5}
            width="100%"
            maxWidth={{ base: '95vw', lg: "1200px" }}
        >
            <VStack spacing={6} align="stretch">
                <Flex
                    justifyContent="space-between"
                    alignItems="center"
                    borderBottom="2px"
                    borderColor="teal.100"
                    pb={4}
                >
                    <HStack spacing={3}>
                        <CalendarIcon color="teal.500" w={6} h={6} />
                        <Heading as="h2" size="lg" color="teal.700">
                            Résumé des Commandes
                        </Heading>
                    </HStack>
                    <Badge
                        colorScheme="teal"
                        p={2}
                        borderRadius="md"
                        fontSize="md"
                    >
                        {sortedDates.length} jours
                    </Badge>
                </Flex>

                <Button
                    leftIcon={<AddIcon />}
                    colorScheme="blue"
                    onClick={onOpen}
                    alignSelf="flex-end"
                >
                    Ajouter un repas
                </Button>

                <Modal isOpen={isOpen} onClose={onClose}>
                    <ModalOverlay />
                    <ModalContent
                        color="black">
                        <ModalHeader>Ajouter un repas</ModalHeader>
                        <ModalCloseButton />
                        <ModalBody>
                            <VStack spacing={4}>
                                <FormControl isRequired>
                                    <FormLabel>Jour</FormLabel>
                                    <Select
                                        value={newMeal.day}
                                        onChange={(e) => setNewMeal({ ...newMeal, day: e.target.value })}
                                    >
                                        <option value="">Sélectionnez un jour</option>
                                        <option value="Lundi">Lundi</option>
                                        <option value="Mardi">Mardi</option>
                                        <option value="Mercredi">Mercredi</option>
                                        <option value="Jeudi">Jeudi</option>
                                        <option value="Vendredi">Vendredi</option>
                                        <option value="Samedi">Samedi</option>
                                        <option value="Dimanche">Dimanche</option>
                                    </Select>
                                </FormControl>

                                <FormControl isRequired>
                                    <FormLabel>Type de repas</FormLabel>
                                    <Select
                                        value={newMeal.type}
                                        onChange={(e) => setNewMeal({ ...newMeal, type: e.target.value })}
                                    >
                                        <option value="">Sélectionnez un type</option>
                                        {mealTypes.map((type) => (
                                            <option key={type} value={type}>
                                                {type}
                                            </option>
                                        ))}
                                    </Select>
                                </FormControl>

                                <FormControl isRequired>
                                    <FormLabel>Nom du plat</FormLabel>
                                    <Input
                                        value={newMeal.name}
                                        onChange={(e) => setNewMeal({ ...newMeal, name: e.target.value })}
                                        placeholder="Entrez le nom du plat"
                                    />
                                </FormControl>

                                <FormControl isRequired>
                                    <FormLabel>Quantité</FormLabel>
                                    <Input
                                        type="number"
                                        min="1"
                                        value={newMeal.quantity}
                                        onChange={(e) => setNewMeal({ ...newMeal, quantity: e.target.value })}
                                    />
                                </FormControl>
                            </VStack>
                        </ModalBody>

                        <ModalFooter>
                            <Button colorScheme="blue" mr={3} onClick={handleAddMeal}>
                                Ajouter
                            </Button>
                            <Button variant="ghost" onClick={onClose}>Annuler</Button>
                        </ModalFooter>
                    </ModalContent>
                </Modal>

                {sortedDates.length === 0 ? (
                    <Card bg="gray.50" p={6} borderRadius="lg">
                        <Text fontSize="lg" color="gray.500" textAlign="center">
                            Aucun plat sélectionné pour le moment
                        </Text>
                    </Card>
                ) : (
                    <SimpleGrid columns={{ base: 1, lg: 1 }} spacing={4}>
                        {sortedDates.map((date, index) => (
                            <Card
                                key={index}
                                borderRadius="lg"
                                overflow="hidden"
                                variant="outline"
                                borderColor="gray.200"
                                transition="all 0.2s"
                                _hover={{ boxShadow: "md" }}
                            >
                                <CardHeader
                                    bg={editMode[date] ? "orange.50" : "gray.50"}
                                    p={2}
                                    borderBottom="1px"
                                    borderColor="gray.200"
                                >
                                    <Flex justify="space-between" align="center">
                                        <HStack>
                                            <Text
                                                fontSize="lg"
                                                fontWeight="bold"
                                                color="teal.700"
                                                textTransform="capitalize"
                                            >
                                                {date.toLowerCase()}
                                            </Text>
                                            <Badge colorScheme="teal" ml={1} py={1} px={2} borderRadius="lg">
                                                {getTotalMealsForDate(date)} plat{getTotalMealsForDate(date) > 1 ? 's' : ''}
                                            </Badge>
                                        </HStack>
                                        <HStack spacing={1}>
                                            <Tooltip label={editMode[date] ? "Terminer l'édition" : "Éditer"}>
                                                <IconButton
                                                    size="md"
                                                    icon={<EditIcon />}
                                                    colorScheme={editMode[date] ? "orange" : "teal"}
                                                    variant="ghost"
                                                    onClick={() => toggleEditMode(date)}
                                                />
                                            </Tooltip>
                                            <Tooltip label="Supprimer la journée">
                                                <IconButton
                                                    size="md"
                                                    icon={<DeleteIcon />}
                                                    colorScheme="red"
                                                    variant="ghost"
                                                    onClick={() => handleRemoveDate(date)}
                                                />
                                            </Tooltip>
                                            <IconButton
                                                size="md"
                                                icon={expandedDates[date] ? <ChevronUpIcon /> : <ChevronDownIcon />}
                                                variant="ghost"
                                                onClick={() => toggleDateExpansion(date)}
                                            />
                                        </HStack>
                                    </Flex>
                                </CardHeader>

                                <Collapse in={expandedDates[date]} animateOpacity>
                                    <CardBody p={4}>
                                        <VStack spacing={3} align="stretch">
                                            {mealSummaryByDate[date].map((item, itemIndex) => (
                                                <Flex
                                                    key={itemIndex}
                                                    p={3}
                                                    bg={itemIndex % 2 === 0 ? 'gray.50' : 'white'}
                                                    borderRadius="md"
                                                    justify="space-between"
                                                    align="center"
                                                    transition="all 0.2s"
                                                    _hover={{ bg: 'gray.100' }}
                                                >
                                                    <HStack spacing={4} flex={1}>
                                                        <Badge
                                                            colorScheme={getMealTypeColor(item.meal)}
                                                            px={2}
                                                            py={1}
                                                        >
                                                            {item.meal.split(' - ')[0]}
                                                        </Badge>
                                                        <Text
                                                            color="gray.700"
                                                            fontSize="sm"
                                                            fontWeight="medium"
                                                        >
                                                            {item.meal.split(' - ').slice(1).join(' - ')}
                                                        </Text>
                                                    </HStack>
                                                    <HStack spacing={4}>
                                                        {editMode[date] ? (
                                                            <Input
                                                                value={item.quantity}
                                                                size="sm"
                                                                width="70px"
                                                                textAlign="center"
                                                                onChange={(e) => handleQuantityChange(date, itemIndex, e.target.value)}
                                                                type="number"
                                                                min="0"
                                                            />
                                                        ) : (
                                                            <Badge
                                                                colorScheme="green"
                                                                fontSize="sm"
                                                                px={3}
                                                                py={1}
                                                            >
                                                                {item.quantity}
                                                            </Badge>
                                                        )}
                                                        {editMode[date] && (
                                                            <IconButton
                                                                aria-label="Supprimer le plat"
                                                                icon={<CloseIcon />}
                                                                size="xs"
                                                                colorScheme="red"
                                                                variant="ghost"
                                                                onClick={() => handleRemoveMeal(date, itemIndex)}
                                                            />
                                                        )}
                                                    </HStack>
                                                </Flex>
                                            ))}
                                        </VStack>
                                    </CardBody>
                                </Collapse>
                            </Card>
                        ))}
                    </SimpleGrid>
                )}

                <Divider my={2} />

                <Flex
                    justifyContent="center"
                    gap={6}
                    direction={{ base: "column", md: "row" }}
                >
                    <Button
                        leftIcon={<CheckIcon />}
                        colorScheme="teal"
                        size="lg"
                        px={8}
                        onClick={() => handleMealValidation(mealSummaryByDate)}
                        isDisabled={sortedDates.length === 0}
                    >
                        Valider les commandes
                    </Button>
                    <Button
                        leftIcon={<RepeatIcon />}
                        colorScheme="gray"
                        size="lg"
                        px={8}
                        onClick={handleUndoLastAction}
                        isDisabled={history.length === 0}
                    >
                        Annuler la dernière action
                    </Button>
                </Flex>
            </VStack>
        </Box>
    );
};

export default MealSummary;