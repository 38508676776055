import React, { useState } from 'react';
import {
    Box,
    Container,
    VStack,
    Card,
    CardHeader,
    CardBody,
    Button,
    Text,
    Flex,
    Heading,
    SimpleGrid,
    IconButton,
    useToast,
    Badge,
    HStack,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalCloseButton,
    useDisclosure,
} from '@chakra-ui/react';
import {
    DownloadIcon,
    ViewIcon,
} from '@chakra-ui/icons';
import PDFPreview from './PDFPreview';

const MultiPDFComponent = () => {
    const [pdfs, setPdfs] = useState([]);
    const [selectedPreview, setSelectedPreview] = useState(null);
    const [rotations, setRotations] = useState({});
    const { isOpen, onOpen, onClose } = useDisclosure();
    const toast = useToast();

    const handleFileUpload = async (event) => {
        const files = Array.from(event.target.files);

        for (const file of files) {
            const formData = new FormData();
            formData.append('pdf', file);

            try {
                const response = await fetch('https://backend-ajscanrepas.onrender.com/process-pdf', {
                    method: 'POST',
                    body: formData
                });

                const data = await response.json();

                setPdfs(prev => [...prev, {
                    name: file.name,
                    pages: data.pages,
                    totalPages: data.totalPages,
                    file: file
                }]);

                toast({
                    title: "Succès",
                    description: `${file.name} a été chargé avec succès`,
                    status: "success",
                    duration: 3000,
                });
            } catch (error) {
                toast({
                    title: "Erreur",
                    description: `Impossible de traiter ${file.name}`,
                    status: "error",
                    duration: 3000,
                });
            }
        }
    };

    const handlePreview = (pdf, pageNumber) => {
        setSelectedPreview({ pdf, pageNumber });
        if (!rotations[`${pdf.name}-${pageNumber}`]) {
            setRotations(prev => ({
                ...prev,
                [`${pdf.name}-${pageNumber}`]: 0
            }));
        }
        onOpen();
    };

    const handleRotation = (pdfName, pageNumber) => (newRotation) => {
        setRotations(prev => ({
            ...prev,
            [`${pdfName}-${pageNumber}`]: newRotation
        }));
    };

    const downloadPage = async (pdf, pageNumber) => {
        try {
            const formData = new FormData();
            formData.append('pdf', pdf.file);
            formData.append('pageNumber', pageNumber.toString());
            formData.append('rotation', rotations[`${pdf.name}-${pageNumber}`] || 0);

            const response = await fetch('https://backend-ajscanrepas.onrender.com/extract-page', {
                method: 'POST',
                body: formData
            });

            if (response.ok) {
                const blob = await response.blob();
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.download = `${pdf.name.replace('.pdf', '')}_page_${pageNumber}.pdf`;
                link.click();
                window.URL.revokeObjectURL(url);

                toast({
                    title: "Succès",
                    description: "La page a été téléchargée",
                    status: "success",
                    duration: 2000,
                });
            }
        } catch (error) {
            toast({
                title: "Erreur",
                description: "Impossible d'extraire la page",
                status: "error",
                duration: 3000,
            });
        }
    };

    const downloadAllPages = async (currentPdf) => {
        let success = true;
        try {
            for (let pageNumber = 1; pageNumber <= currentPdf.totalPages; pageNumber++) {
                const formData = new FormData();
                formData.append('pdf', currentPdf.file);
                formData.append('pageNumber', pageNumber.toString());
                formData.append('rotation', rotations[`${currentPdf.name}-${pageNumber}`] || 0);

                const response = await fetch('https://backend-ajscanrepas.onrender.com/extract-page', {
                    method: 'POST',
                    body: formData
                });

                if (response.ok) {
                    const blob = await response.blob();
                    const url = window.URL.createObjectURL(blob);
                    const link = document.createElement('a');
                    link.href = url;
                    link.download = `${currentPdf.name.replace('.pdf', '')}_page_${pageNumber}.pdf`;
                    link.click();
                    window.URL.revokeObjectURL(url);
                } else {
                    success = false;
                }
            }

            toast({
                title: success ? "Succès" : "Attention",
                description: success
                    ? "Toutes les pages ont été téléchargées"
                    : "Certaines pages n'ont pas pu être téléchargées",
                status: success ? "success" : "warning",
                duration: 3000,
            });
        } catch (error) {
            toast({
                title: "Erreur",
                description: "Impossible de télécharger toutes les pages",
                status: "error",
                duration: 3000,
            });
        }
    };

    return (
        <Container maxW="container.xl" py={8}>
            <VStack spacing={8}>
                <Card w="full">
                    <CardHeader>
                        <Heading size="lg" color="teal.600">
                            Extracteur de Pages PDF
                        </Heading>
                    </CardHeader>
                    <CardBody>
                        <Button
                            as="label"
                            htmlFor="pdf-upload"
                            colorScheme="teal"
                            cursor="pointer"
                        >
                            Sélectionner des PDFs
                            <input
                                id="pdf-upload"
                                type="file"
                                multiple
                                accept=".pdf"
                                onChange={handleFileUpload}
                                style={{ display: 'none' }}
                            />
                        </Button>
                    </CardBody>
                </Card>

                <SimpleGrid columns={{ base: 1, md: 1, lg: 1 }} spacing={6} w="full">
                    {pdfs.map((pdf, index) => (
                        <Card key={index} w="100%">
                            <CardHeader>
                                <VStack align="stretch" spacing={2}>
                                    <HStack justify="space-between">
                                        <Text fontWeight="bold" noOfLines={1}>
                                            {pdf.name}
                                        </Text>
                                        <Button
                                            size="sm"
                                            colorScheme="teal"
                                            leftIcon={<DownloadIcon />}
                                            onClick={() => downloadAllPages(pdf)}
                                        >
                                            Tout télécharger
                                        </Button>
                                    </HStack>
                                    <Flex justifyContent="start">
                                        <Badge colorScheme="teal" p={2}
                                            borderRadius="md"
                                        >
                                            {pdf.totalPages} page{pdf.totalPages > 1 ? 's' : ''}
                                        </Badge>
                                    </Flex>
                                </VStack>
                            </CardHeader>
                            <CardBody>
                                <Flex
                                    flexWrap="wrap"
                                    gap={3}
                                    justifyContent="flex-start"
                                    alignItems="center"
                                >
                                    {Array.from({ length: pdf.totalPages }).map((_, pageIdx) => (
                                        <Box
                                            key={pageIdx}
                                            p={2}
                                            bg="gray.50"
                                            borderRadius="md"
                                            maxWidth="190px"
                                            flexBasis="calc(33.333% - 1rem)"
                                        >
                                            <HStack
                                                justify="space-between"
                                                mb={2}
                                            >
                                                <Text fontSize="sm">Page {pageIdx + 1}</Text>
                                                <HStack>
                                                    <IconButton
                                                        icon={<ViewIcon />}
                                                        size="sm"
                                                        colorScheme="blue"
                                                        onClick={() => handlePreview(pdf, pageIdx + 1)}
                                                        aria-label="Aperçu de la page"
                                                    />
                                                    <IconButton
                                                        icon={<DownloadIcon />}
                                                        size="sm"
                                                        colorScheme="teal"
                                                        onClick={() => downloadPage(pdf, pageIdx + 1)}
                                                        aria-label="Télécharger la page"
                                                    />
                                                </HStack>
                                            </HStack>
                                        </Box>
                                    ))}
                                </Flex>
                            </CardBody>
                        </Card>
                    ))}
                </SimpleGrid>
            </VStack>

            <Modal isOpen={isOpen} onClose={onClose} size="4xl" isCentered>
                <ModalOverlay />
                <ModalContent maxW="90vw" h="80vh">
                    <ModalHeader>
                        {selectedPreview && (
                            <Text>
                                {selectedPreview.pdf.name} - Page {selectedPreview.pageNumber}
                            </Text>
                        )}
                    </ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        {selectedPreview && (
                            <PDFPreview
                                pdfFile={selectedPreview.pdf.file}
                                pageNumber={selectedPreview.pageNumber}
                                rotation={rotations[`${selectedPreview.pdf.name}-${selectedPreview.pageNumber}`] || 0}
                                onRotate={handleRotation(selectedPreview.pdf.name, selectedPreview.pageNumber)}
                            />
                        )}
                    </ModalBody>
                </ModalContent>
            </Modal>
        </Container>
    );
};

export default MultiPDFComponent;