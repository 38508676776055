import React, { useState, useEffect, useMemo } from 'react';
import {
    Box,
    Button,
    Flex,
    Heading,
    Text,
    Badge,
    Card,
    CardHeader,
    CardBody,
    SimpleGrid,
    Stack,
    VStack,
    HStack,
    IconButton,
    Stat,
    StatLabel,
    StatNumber,
    StatGroup,
    Divider,
    useToast,
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    AccordionIcon,
    Tooltip
} from '@chakra-ui/react';
import {
    DownloadIcon,
    EmailIcon,
    ExternalLinkIcon,
    CalendarIcon,
    InfoIcon
} from '@chakra-ui/icons';
import { jsPDF } from 'jspdf';
import { useTableCount } from '../../contexts/TableCountContext';
import * as XLSX from 'xlsx';

const TotalMeals = ({ mealsSummary, data }) => {
    const [isCopying, setIsCopying] = useState(false);
    const toast = useToast();
    const { tableCount } = useTableCount();
    const [dishTypeStats, setDishTypeStats] = useState({});

    const parseFrenchDate = (dateStr) => {
        const monthMap = {
            'janvier': '01', 'février': '02', 'mars': '03', 'avril': '04',
            'mai': '05', 'juin': '06', 'juillet': '07', 'août': '08',
            'septembre': '09', 'octobre': '10', 'novembre': '11', 'décembre': '12'
        };

        const [_, jour, mois, annee = new Date().getFullYear()] = dateStr.toLowerCase().split(' ');
        const moisNum = monthMap[mois];
        return new Date(`${annee}-${moisNum}-${jour.padStart(2, '0')}`);
    };

    const getMealInfo = (mealName) => {
        const parts = mealName.toLowerCase().split(' - ');
        return {
            mealType: parts[0] || 'inconnu',
            dishType: parts[1] || 'inconnu'
        };
    };

    const getMealOrder = (data) => {
        const order = new Map();
        let index = 0;

        if (data && data.length > 0) {
            data.forEach(table => {
                table.forEach((row) => {
                    if (row.length >= 2) {
                        const mealType = row[0]?.toLowerCase() || '';
                        const dishType = row[1]?.toLowerCase() || '';
                        const combinedKey = `${mealType} - ${dishType}`;
                        if (!order.has(combinedKey)) {
                            order.set(combinedKey, index++);
                        }
                    }
                });
            });
        }

        return order;
    };

    const calculateDishTypeStats = () => {
        const stats = {};
        const allMealTypes = new Set();

        if (data && data.length > 0) {
            data.forEach(table => {
                table.forEach((row) => {
                    if (row.length >= 2) {
                        const mealType = row[0]?.toLowerCase() || '';
                        const dishType = row[1]?.toLowerCase() || '';
                        if (mealType && dishType) {
                            allMealTypes.add(`${mealType} - ${dishType}`);
                        }
                    }
                });
            });
        }

        Object.keys(mealsSummary).forEach((date) => {
            Object.keys(mealsSummary[date]).forEach((mealName) => {
                const { mealType, dishType } = getMealInfo(mealName);
                allMealTypes.add(`${mealType} - ${dishType}`);
            });
        });

        Object.keys(mealsSummary).forEach((date) => {
            if (!stats[date]) {
                stats[date] = {};
            }

            allMealTypes.forEach((combinedType) => {
                stats[date][combinedType] = 0;
            });

            Object.entries(mealsSummary[date]).forEach(([mealName, quantity]) => {
                const { mealType, dishType } = getMealInfo(mealName);
                const combinedKey = `${mealType} - ${dishType}`;
                stats[date][combinedKey] += quantity;
            });
        });

        setDishTypeStats(stats);
    };

    useEffect(() => {
        calculateDishTypeStats();
    }, [mealsSummary, data]);

    const mergedMealsSummary = {};
    Object.keys(mealsSummary).forEach((date) => {
        if (!mergedMealsSummary[date]) {
            mergedMealsSummary[date] = {};
        }
        Object.entries(mealsSummary[date]).forEach(([mealName, quantity]) => {
            if (mergedMealsSummary[date][mealName]) {
                mergedMealsSummary[date][mealName] += quantity;
            } else {
                mergedMealsSummary[date][mealName] = quantity;
            }
        });
    });

    const sortedDates = Object.keys(mergedMealsSummary).sort((a, b) => {
        const dateA = parseFrenchDate(a);
        const dateB = parseFrenchDate(b);
        return dateA - dateB;
    });

    const totalMealsCount = sortedDates.reduce((total, date) => {
        const mealsForDate = mergedMealsSummary[date];
        const dateTotal = Object.values(mealsForDate).reduce((acc, quantity) => acc + quantity, 0);
        return total + dateTotal;
    }, 0);

    const exportToExcel = () => {
        if (!data || data.length === 0) {
            toast({
                title: "Erreur",
                description: "Aucune donnée à exporter",
                status: "error",
                duration: 3000,
                isClosable: true,
            });
            return;
        }

        try {
            let excelData = JSON.parse(JSON.stringify(data));

            excelData = excelData.map(table => {
                return table.map((row, rowIndex) => {
                    if (rowIndex === 0) return row;

                    for (let i = 2; i < row.length; i += 2) {
                        const mealName = row[i];
                        const date = table[0][i];
                        const mealType = row[0];
                        const dishType = row[1];

                        const fullMealName = `${mealType.toLowerCase()} - ${dishType.toLowerCase()} - ${mealName.toLowerCase()}`;
                        const quantity = mealsSummary[date]?.[fullMealName] || 0;

                        if (i + 1 < row.length) {
                            row[i + 1] = quantity;
                        }
                    }
                    return row;
                });
            });

            const wb = XLSX.utils.book_new();

            const defaultStyle = {
                alignment: { vertical: "center", horizontal: "center", wrapText: true },
                border: {
                    top: { style: "thin" },
                    bottom: { style: "thin" },
                    left: { style: "thin" },
                    right: { style: "thin" }
                },
                font: { name: "Arial", sz: "11" }
            };

            const headerStyle = {
                ...defaultStyle,
                font: { name: "Arial", sz: "11", bold: true, color: { rgb: "FFFFFF" } },
                fill: { patternType: "solid", fgColor: { rgb: "4F81BD" } }
            };

            const typeColumnStyle = {
                ...defaultStyle,
                font: { name: "Arial", sz: "11", bold: true },
                fill: { patternType: "solid", fgColor: { rgb: "DCE6F1" } }
            };

            const quantityStyle = {
                ...defaultStyle,
                font: { name: "Arial", sz: "11", bold: true },
                fill: { patternType: "solid", fgColor: { rgb: "E2EFDA" } }
            };

            excelData.forEach((table, index) => {
                const ws = XLSX.utils.aoa_to_sheet(table);

                ws['!rows'] = Array(table.length).fill({ hpt: 30 });
                ws['!cols'] = [
                    { wch: 15 },
                    { wch: 15 },
                    ...Array((table[0].length - 2) / 2).fill(null).flatMap(() => [
                        { wch: 30 },
                        { wch: 10 }
                    ])
                ];

                const range = XLSX.utils.decode_range(ws['!ref']);
                for (let R = range.s.r; R <= range.e.r; ++R) {
                    for (let C = range.s.c; C <= range.e.c; ++C) {
                        const cellRef = XLSX.utils.encode_cell({ r: R, c: C });
                        if (!ws[cellRef]) continue;

                        if (R === 0) {
                            ws[cellRef].s = headerStyle;
                        } else if (C <= 1) {
                            ws[cellRef].s = typeColumnStyle;
                        } else if (C % 2 === 1) {
                            ws[cellRef].s = quantityStyle;
                        } else {
                            ws[cellRef].s = defaultStyle;
                        }
                    }
                }

                XLSX.utils.book_append_sheet(wb, ws, `Tableau ${index + 1}`);
            });

            const summaryData = [
                ['Date', 'Type de Repas', 'Type de Plat', 'Quantité', 'Tableaux'],
                ...Object.entries(dishTypeStats).flatMap(([date, stats]) =>
                    Object.entries(stats).map(([type, count]) => [
                        date,
                        type.split(' - ')[0],
                        type.split(' - ')[1],
                        count,
                        tableCount
                    ])
                )
            ];

            const ws_summary = XLSX.utils.aoa_to_sheet(summaryData);
            XLSX.utils.book_append_sheet(wb, ws_summary, 'Résumé');

            const date = new Date().toISOString().split('T')[0];
            XLSX.writeFile(wb, `Commandes_Repas_${date}.xlsx`);

            toast({
                title: "Succès",
                description: "Le fichier Excel a été généré avec succès",
                status: "success",
                duration: 3000,
                isClosable: true,
            });

        } catch (error) {
            console.error('Erreur lors de l\'export Excel:', error);
            toast({
                title: "Erreur",
                description: "Une erreur est survenue lors de la génération du fichier Excel",
                status: "error",
                duration: 3000,
                isClosable: true,
            });
        }
    };

    const generateEmailBody = () => {
        let body = "Voici le total des repas commandés :\n\n";
        sortedDates.forEach((date) => {
            body += `Date: ${date}\n`;
            if (dishTypeStats[date]) {
                body += "Statistiques par type de plat :\n";
                Object.entries(dishTypeStats[date])
                    .sort(([typeA], [typeB]) => typeA.localeCompare(typeB))
                    .forEach(([combinedType, count]) => {
                        body += `- ${combinedType}: ${count} plats / ${tableCount} tableaux\n`;
                    });
            }
            body += '\nDétail des plats :\n';
            Object.entries(mergedMealsSummary[date])
                .sort(([mealA], [mealB]) => mealA.localeCompare(mealB))
                .forEach(([meal, quantity]) => {
                    body += `- ${meal}: ${quantity} plat(s)\n`;
                });
            body += '\n';
        });
        body += `\nTotal global des repas commandés : ${totalMealsCount}`;
        return body;
    };

    const copyToClipboard = async () => {
        setIsCopying(true);
        const emailBody = generateEmailBody();

        try {
            await navigator.clipboard.writeText(emailBody);
            toast({
                title: "Succès !",
                description: "Le texte a été copié dans le presse-papiers.",
                status: "success",
                duration: 3000,
                isClosable: true,
            });
        } catch (error) {
            try {
                const textarea = document.createElement('textarea');
                textarea.value = emailBody;
                document.body.appendChild(textarea);
                textarea.select();
                document.execCommand('copy');
                document.body.removeChild(textarea);
                toast({
                    title: "Succès !",
                    description: "Le texte a été copié dans le presse-papiers.",
                    status: "success",
                    duration: 3000,
                    isClosable: true,
                });
            } catch (fallbackError) {
                toast({
                    title: "Erreur",
                    description: "Le texte n'a pas pu être copié. Veuillez réessayer.",
                    status: "error",
                    duration: 3000,
                    isClosable: true,
                });
            }
        } finally {
            setIsCopying(false);
        }
    };

    const downloadPDF = () => {
        const doc = new jsPDF();
        const logoUrl = `${process.env.PUBLIC_URL}/AJScanRepasLogo512.jpg`;
        const image = new Image();
        image.src = logoUrl;

        image.onload = function () {
            const logoWidth = 50;
            const logoHeight = 50;
            doc.addImage(image, 'JPEG', 10, 0, logoWidth, logoHeight);

            doc.setFont("helvetica", "bold");
            doc.setFontSize(18);

            const pageWidth = doc.internal.pageSize.getWidth();
            let yPosition = logoHeight + 0;

            doc.text("Total des repas commandés", pageWidth / 2, yPosition, { align: 'center' });

            yPosition += 15;

            doc.setFont("helvetica", "normal");
            doc.setFontSize(10);

            sortedDates.forEach((date) => {
                doc.setFont("helvetica", "bold");
                doc.text(`${date.toUpperCase()}`, 10, yPosition);
                yPosition += 8;

                if (dishTypeStats[date]) {
                    doc.setFont("helvetica", "italic");
                    doc.text("Statistiques par type de plat :", 10, yPosition);
                    yPosition += 7;

                    Object.entries(dishTypeStats[date])
                        .sort(([typeA], [typeB]) => {
                            const orderA = mealOrder.get(typeA) ?? Number.MAX_VALUE;
                            const orderB = mealOrder.get(typeB) ?? Number.MAX_VALUE;
                            return orderA - orderB;
                        })
                        .forEach(([combinedType, count]) => {
                            doc.text(`- ${combinedType}: ${count} plats / ${tableCount} tableaux`, 15, yPosition);
                            yPosition += 7;
                            if (yPosition > 280) {
                                doc.addPage();
                                yPosition = 10;
                            }
                        });
                }

                doc.setFont("helvetica", "normal");
                Object.entries(mergedMealsSummary[date])
                    .sort(([mealA, _], [mealB, __]) => {
                        const typeA = getMealInfo(mealA).mealType + ' - ' + getMealInfo(mealA).dishType;
                        const typeB = getMealInfo(mealB).mealType + ' - ' + getMealInfo(mealB).dishType;
                        const orderA = mealOrder.get(typeA) ?? Number.MAX_VALUE;
                        const orderB = mealOrder.get(typeB) ?? Number.MAX_VALUE;
                        if (orderA !== orderB) return orderA - orderB;
                        return mealA.localeCompare(mealB);
                    })
                    .forEach(([meal, quantity]) => {
                        doc.text(`- ${meal}: ${quantity} plat(s)`, 10, yPosition);
                        yPosition += 7;
                        if (yPosition > 280) {
                            doc.addPage();
                            yPosition = 10;
                        }
                    });

                yPosition += 5;
            });

            doc.setFont("helvetica", "bold");
            doc.setFontSize(14);
            doc.text(`Nombre total de plats commandés : ${totalMealsCount}`, 10, yPosition + 10);

            doc.save('TotalRepasCommandes.pdf');
        };
    };

    const mealOrder = getMealOrder(data);

    return (
        <Card borderRadius="xl" boxShadow="xl" bg="white" overflow="hidden"
            w="100%" maxWidth={{ base: '100%', lg: "1200px" }} mt={6}
        >
            <CardHeader bg="teal.50" borderBottom="2px" borderColor="teal.100">
                <VStack spacing={4}>
                    <Stack
                        direction={{ base: "column", md: "row" }}
                        w="full"
                        spacing={4}
                        justify="space-between"
                        align={{ base: "center", md: "center" }}
                    >
                        {/* Titre avec icône */}
                        <HStack spacing={3} justify={{ base: "center", md: "flex-start" }}>
                            <CalendarIcon w={6} h={6} color="teal.500" />
                            <Heading
                                size="lg"
                                color="teal.700"
                                textAlign={{ base: "center", md: "left" }}
                            >
                                Récapitulatif des Commandes
                            </Heading>
                        </HStack>

                        {/* Badge avec tooltip */}
                        <Tooltip label="Nombre total de tables analysées">
                            <Badge
                                colorScheme="teal"
                                p={2}
                                borderRadius="md"
                                fontSize="md"
                            >
                                {tableCount} tableau{tableCount > 1 ? 'x' : ''}
                            </Badge>
                        </Tooltip>
                    </Stack>

                    <StatGroup
                        w="full"
                        bg="white"
                        p={4}
                        borderRadius="lg"
                        boxShadow="sm"
                    >
                        <Stat>
                            <StatLabel>Total Plats</StatLabel>
                            <StatNumber>{totalMealsCount}</StatNumber>
                        </Stat>
                        <Stat>
                            <StatLabel>Jours</StatLabel>
                            <StatNumber>{sortedDates.length}</StatNumber>
                        </Stat>
                        <Stat>
                            <StatLabel>Moyenne/Jour</StatLabel>
                            <StatNumber>
                                {sortedDates.length > 0 ?
                                    Math.round(totalMealsCount / sortedDates.length) : 0}
                            </StatNumber>
                        </Stat>
                    </StatGroup>
                </VStack>
            </CardHeader>

            <CardBody pt={6} px={2}>
                {sortedDates.length === 0 ? (
                    <Card bg="gray.50" p={6}>
                        <Text fontSize="lg" textAlign="center" color="gray.500">
                            Aucune commande enregistrée
                        </Text>
                    </Card>
                ) : (
                    <Accordion allowMultiple defaultIndex={[0]}>
                        {sortedDates.map((date, index) => (
                            <AccordionItem
                                key={index}
                                border="none"
                                mb={4}
                            >
                                <AccordionButton
                                    bg="gray.50"
                                    _hover={{ bg: 'gray.100' }}
                                    borderRadius="lg"
                                >
                                    <HStack flex="1" justify="space-between">
                                        <HStack>
                                            <Text
                                                fontSize="lg"
                                                fontWeight="bold"
                                                color="teal.700"
                                                textTransform="capitalize"
                                            >
                                                {date.toLowerCase()}
                                            </Text>
                                            <Badge colorScheme="teal">
                                                {Object.values(mergedMealsSummary[date]).reduce((a, b) => a + b, 0)} plats
                                            </Badge>
                                        </HStack>
                                        <AccordionIcon />
                                    </HStack>
                                </AccordionButton>

                                <AccordionPanel pb={4}>
                                    <SimpleGrid columns={{ base: 1, lg: 2 }} spacing={6} mt={4}>
                                        <Card variant="outline">
                                            <CardHeader bg="blue.50" py={2}>
                                                <HStack>
                                                    <InfoIcon color="blue.500" />
                                                    <Text fontWeight="bold" color="blue.700">
                                                        Statistiques par type
                                                    </Text>
                                                </HStack>
                                            </CardHeader>
                                            <CardBody>
                                                <VStack align="stretch" spacing={3}>
                                                    {Object.entries(dishTypeStats[date] || {})
                                                        .sort(([typeA], [typeB]) => {
                                                            const orderA = mealOrder.get(typeA) ?? Number.MAX_VALUE;
                                                            const orderB = mealOrder.get(typeB) ?? Number.MAX_VALUE;
                                                            return orderA - orderB;
                                                        })
                                                        .map(([type, count], idx) => (
                                                            <HStack
                                                                key={idx}
                                                                justify="space-between"
                                                                p={2}
                                                                bg={idx % 2 === 0 ? 'gray.50' : 'white'}
                                                                borderRadius="md"
                                                            >
                                                                <Text fontSize="sm">{type}</Text>
                                                                <Badge
                                                                    colorScheme={count !== tableCount ? "orange" : "green"}
                                                                >
                                                                    {count} / {tableCount}
                                                                </Badge>
                                                            </HStack>
                                                        ))}
                                                </VStack>
                                            </CardBody>
                                        </Card>

                                        <Card variant="outline">
                                            <CardHeader bg="purple.50" py={2}>
                                                <HStack>
                                                    <InfoIcon color="purple.500" />
                                                    <Text fontWeight="bold" color="purple.700">
                                                        Détail des plats
                                                    </Text>
                                                </HStack>
                                            </CardHeader>
                                            <CardBody>
                                                <VStack align="stretch" spacing={3}>
                                                    {Object.entries(mergedMealsSummary[date])
                                                        .sort(([mealA, _], [mealB, __]) => {
                                                            const typeA = getMealInfo(mealA).mealType + ' - ' + getMealInfo(mealA).dishType;
                                                            const typeB = getMealInfo(mealB).mealType + ' - ' + getMealInfo(mealB).dishType;
                                                            const orderA = mealOrder.get(typeA) ?? Number.MAX_VALUE;
                                                            const orderB = mealOrder.get(typeB) ?? Number.MAX_VALUE;
                                                            if (orderA !== orderB) return orderA - orderB;
                                                            return mealA.localeCompare(mealB);
                                                        })
                                                        .map(([meal, quantity], idx) => (
                                                            <Flex
                                                                key={idx}
                                                                justify="space-between"
                                                                align="center"
                                                                p={2}
                                                                bg={idx % 2 === 0 ? 'gray.50' : 'white'}
                                                                borderRadius="md"
                                                            >
                                                                <Text fontSize="sm" noOfLines={2}>
                                                                    {meal}
                                                                </Text>
                                                                <Badge colorScheme="green" ml={2}>
                                                                    {quantity}
                                                                </Badge>
                                                            </Flex>
                                                        ))}
                                                </VStack>
                                            </CardBody>
                                        </Card>
                                    </SimpleGrid>
                                </AccordionPanel>
                            </AccordionItem>
                        ))}
                    </Accordion>
                )}

                <Divider my={6} />

                <SimpleGrid columns={{ base: 1, md: 3 }} spacing={4}>
                    <Button
                        leftIcon={<EmailIcon />}
                        colorScheme="blue"
                        size="lg"
                        onClick={copyToClipboard}
                        isLoading={isCopying}
                        loadingText="Copie..."
                    >
                        Copier pour email
                    </Button>

                    <Button
                        leftIcon={<DownloadIcon />}
                        colorScheme="teal"
                        size="lg"
                        onClick={downloadPDF}
                    >
                        Télécharger PDF
                    </Button>

                    <Button
                        leftIcon={<ExternalLinkIcon />}
                        colorScheme="green"
                        size="lg"
                        onClick={exportToExcel}
                    >
                        Exporter Excel
                    </Button>
                </SimpleGrid>
            </CardBody>
        </Card>
    );
};

export default TotalMeals;