import React from 'react';
import {
    Grid,
    GridItem,
    Box,
    Flex,
    Container,
    Image,
    Heading,
    Text,
    useBreakpointValue
} from "@chakra-ui/react";
import NavbarLogin from '../common/NavbarLogin';
import Footer from '../common/Footer';
import LoginForm from '../forms/LoginForm';

export default function Accueil() {
    // Responsive design adjustments
    const showIllustration = useBreakpointValue({ base: false, lg: true });
    const containerWidth = useBreakpointValue({ base: "95%", md: "90%", lg: "85%" });

    return (
        <Box minH="100vh" position="relative" overflow="hidden">
            {/* Background with animated gradient */}
            <Box
                position="absolute"
                top="0"
                left="0"
                right="0"
                bottom="0"
                bgGradient="linear(to-br, teal.500, blue.500)"
                opacity="0.9"
                zIndex="0"
            />

            {/* Decorative circles */}
            <Box
                position="absolute"
                top="-10%"
                left="-5%"
                width="300px"
                height="300px"
                borderRadius="full"
                bg="whiteAlpha.100"
                zIndex="1"
            />
            <Box
                position="absolute"
                bottom="-15%"
                right="-10%"
                width="400px"
                height="400px"
                borderRadius="full"
                bg="whiteAlpha.50"
                zIndex="1"
            />

            <Grid
                templateAreas={`"header header" "main main" "footer footer"`}
                gridTemplateRows={'auto 1fr auto'}
                gridTemplateColumns='1fr'
                position="relative"
                zIndex="2"
                minH="100vh"
            >
                <GridItem area={'header'}>
                    <NavbarLogin />
                </GridItem>

                <GridItem area={'main'}>
                    <Container maxW={containerWidth} py={{ base: 8, lg: 16 }}>
                        <Flex
                            direction={{ base: 'column', lg: 'row' }}
                            align="center"
                            justify="space-between"
                            gap={{ base: 8, lg: 12 }}
                        >
                            {/* Left side - Welcome text and illustration */}
                            {showIllustration && (
                                <Flex
                                    direction="column"
                                    flex="1"
                                    align={{ base: 'center', lg: 'flex-start' }}
                                    pr={{ lg: 8 }}
                                >
                                    <Heading
                                        size="2xl"
                                        color="white"
                                        mb={6}
                                        lineHeight="1.2"
                                    >
                                        Bienvenue sur
                                        <Text
                                            as="span"
                                            display="block"
                                            bgGradient="linear(to-r, teal.200, blue.200)"
                                            bgClip="text"
                                        >
                                            AJ Scan Repas
                                        </Text>
                                    </Heading>
                                    <Text
                                        color="whiteAlpha.900"
                                        fontSize="lg"
                                        mb={8}
                                    >
                                        Simplifiez la gestion de vos commandes de repas avec notre solution intuitive.
                                    </Text>
                                    <Box
                                        maxW="400px"
                                        w="full"
                                        position="relative"
                                    >
                                        <Box
                                            position="absolute"
                                            top="50%"
                                            left="50%"
                                            transform="translate(-50%, -50%)"
                                            width="120%"
                                            height="120%"
                                            bg="whiteAlpha.200"
                                            borderRadius="2xl"
                                            filter="blur(40px)"
                                        />
                                        <Image
                                            src="/LogoAJS89.jpg"
                                            alt="AJ Scan Repas Illustration"
                                            width="100%"
                                            style={{ position: 'relative' }}
                                            borderRadius="xl"
                                            boxShadow="xl"
                                        />
                                    </Box>
                                </Flex>
                            )}

                            {/* Right side - Login form */}
                            <Box
                                flex="1"
                                maxW={{ base: "100%", lg: "450px" }}
                                w="full"
                            >
                                <Box
                                    bg="gray.50"
                                    borderRadius="2xl"
                                    boxShadow="2xl"
                                    overflow="hidden"
                                    p={{ base: 6, md: 8 }}
                                    position="relative"
                                    _before={{
                                        content: '""',
                                        position: "absolute",
                                        top: 0,
                                        left: 0,
                                        right: 0,
                                        height: "4px",
                                        bgGradient: "linear(to-r, teal.500, blue.500)"
                                    }}
                                >
                                    <LoginForm />
                                </Box>
                            </Box>
                        </Flex>
                    </Container>
                </GridItem>

                <GridItem area={'footer'}>
                    <Footer />
                </GridItem>
            </Grid>
        </Box>
    );
};