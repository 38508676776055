import React, { useState, useEffect } from 'react';
import {
    Box,
    Container,
    Heading,
    VStack,
    IconButton,
    useBreakpointValue,
    useColorModeValue,
    Flex,
    Text,
    Card,
    CardHeader,
    CardBody,
    Fade,
    Badge,
} from "@chakra-ui/react";
import {
    ChevronUpIcon,
    InfoIcon
} from '@chakra-ui/icons';
import MultiPDFComponent from "../common/MultiPDFComponent";
import Navbar from '../common/Navbar';
import Footer from '../common/Footer';

export default function ScanRepas() {
    const [resetFunction, setResetFunction] = useState(null);
    const [showScrollButton, setShowScrollButton] = useState(false);
    const [isPageLoaded, setIsPageLoaded] = useState(false);

    const containerWidth = useBreakpointValue({ base: "98%", lg: "92%", xl: "1800px" });
    const bgGradient = useColorModeValue(
        "linear(to-br, teal.500, blue.500)",
        "linear(to-br, teal.800, blue.800)"
    );

    useEffect(() => {
        setIsPageLoaded(true);
    }, []);

    const handleReset = (resetFunc) => {
        setResetFunction(() => resetFunc);
    };

    useEffect(() => {
        const handleScroll = () => {
            const shouldShow = window.scrollY > 300;
            setShowScrollButton(shouldShow);
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    return (
        <Box minH="100vh" position="relative" overflow="hidden">
            {/* Background with pattern overlay */}
            <Box
                position="absolute"
                top="0"
                left="0"
                right="0"
                bottom="0"
                bgGradient={bgGradient}
                opacity="0.98"
                zIndex="0"
            />

            {/* Pattern overlay */}
            <Box
                position="absolute"
                top="0"
                left="0"
                right="0"
                bottom="0"
                backgroundImage="url('data:image/svg+xml,...')"
                backgroundSize="30px 30px"
                opacity="0.05"
                zIndex="1"
            />

            {/* Main Content */}
            <Flex direction="column" minH="100vh" position="relative" zIndex="2">
                {/* Header */}
                <Navbar onResetScan={resetFunction} />

                {/* Main Content */}
                <Box flex="1" py={{ base: 8, lg: 16 }}>
                    <Container maxW={containerWidth}>
                        <Fade in={isPageLoaded}>
                            <Card
                                bg={useColorModeValue('white', 'gray.800')}
                                borderRadius="xl"
                                boxShadow="2xl"
                                overflow="hidden"
                            >
                                <CardHeader
                                    bg={useColorModeValue('gray.50', 'gray.700')}
                                    borderBottom="1px"
                                    borderColor={useColorModeValue('gray.200', 'gray.600')}
                                    py={6}
                                >
                                    <Flex justify={{ base: "center", lg: "space-between" }} align="center">
                                        <Heading
                                            as="h1"
                                            fontSize={{ base: "2xl", lg: "3xl" }}
                                            color="teal.700"
                                        >
                                            Extracteur PDF
                                        </Heading>
                                        <Badge
                                            display={{ base: "none", lg: "block" }}
                                            colorScheme="teal"
                                            fontSize="md"
                                            px={3}
                                            py={1}
                                            borderRadius="full"
                                        >
                                            Extraire un PDF
                                        </Badge>
                                    </Flex>

                                    <Text
                                        mt={2}
                                        color={useColorModeValue('gray.600', 'gray.300')}
                                        fontSize="md"
                                    >
                                        Chargez une photo du menu pour commencer l'analyse
                                    </Text>
                                </CardHeader>

                                <CardBody p={0}>
                                    <Box
                                        bg={useColorModeValue('gray.50', 'gray.700')}
                                    >
                                        <MultiPDFComponent onReset={handleReset} />
                                    </Box>
                                </CardBody>
                            </Card>
                        </Fade>
                    </Container>
                </Box>

                {/* Footer */}
                <Footer />
            </Flex>

            {/* Scroll to top button */}
            <Fade in={showScrollButton}>
                <IconButton
                    aria-label="Retour en haut de la page"
                    icon={<ChevronUpIcon />}
                    position="fixed"
                    bottom="6"
                    right="6"
                    size="lg"
                    colorScheme="teal"
                    variant="solid"
                    border="2px solid"
                    borderColor={useColorModeValue('white', 'gray.700')}
                    boxShadow="xl"
                    onClick={scrollToTop}
                    display={{ base: 'none', lg: 'flex' }}
                    opacity="0.9"
                    _hover={{
                        opacity: "1",
                        transform: "translateY(-2px)",
                        boxShadow: "2xl"
                    }}
                    transition="all 0.2s"
                    zIndex={999}
                    borderRadius="full"
                />
            </Fade>
        </Box>
    );
}