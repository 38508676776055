import React, { useState, useEffect } from 'react';
import {
    Box,
    Button,
    Flex,
    Heading,
    Text,
    Badge,
    Card,
    CardHeader,
    CardBody,
    SimpleGrid,
    Stack,
    VStack,
    HStack,
    Stat,
    StatLabel,
    StatNumber,
    StatGroup,
    Divider,
    useToast,
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    AccordionIcon,
    Tooltip
} from '@chakra-ui/react';
import {
    DownloadIcon,
    EmailIcon,
    ExternalLinkIcon,
    CalendarIcon,
    InfoIcon
} from '@chakra-ui/icons';
import { jsPDF } from 'jspdf';
import { useTableCount } from '../../contexts/TableCountContext';
import ExcelJS from 'exceljs';

const TotalMeals = ({ mealsSummary, data }) => {
    const [isCopying, setIsCopying] = useState(false);
    const toast = useToast();
    const { tableCount } = useTableCount();
    const [dishTypeStats, setDishTypeStats] = useState({});

    const parseFrenchDate = (dateStr) => {
        const dayOrder = {
            'lundi': 1,
            'mardi': 2,
            'mercredi': 3,
            'jeudi': 4,
            'vendredi': 5,
            'samedi': 6,
            'dimanche': 7
        };

        // Comme on a maintenant juste le jour de la semaine, 
        // on retourne un numéro qui permettra de trier les jours
        return dayOrder[dateStr.toLowerCase()] || 8;
    };


    const getMealOrder = () => {
        const categoryOrder = {
            'entree': 0,
            'plat principal': 1,
            'accompagnement': 2,
            'fromage': 3,
            'dessert': 4,
            'diner': 5
        };

        return categoryOrder;
    };

    const calculateDishTypeStats = () => {
        const stats = {};
        const categories = ['entree', 'plat principal', 'accompagnement', 'fromage', 'dessert', 'diner'];

        Object.keys(mealsSummary).forEach((date) => {
            if (!stats[date]) {
                stats[date] = {};
                categories.forEach(category => {
                    stats[date][category] = {
                        count: 0,     // Nombre total de plats dans cette catégorie
                        total: tableCount  // Nombre total de tableaux à considérer
                    };
                });
            }

            Object.entries(mealsSummary[date]).forEach(([mealName, quantity]) => {
                const category = mealName.split(' - ')[0].toLowerCase();
                if (categories.includes(category)) {
                    if (quantity > 0) {
                        stats[date][category].count += quantity;
                    }
                }
            });
        });

        setDishTypeStats(stats);
    };

    useEffect(() => {
        calculateDishTypeStats();
    }, [mealsSummary, data]);

    const sortByCategory = (a, b) => {
        const categoryOrder = getMealOrder();
        const categoryA = a.split(' - ')[0].toLowerCase();
        const categoryB = b.split(' - ')[0].toLowerCase();

        const orderA = categoryOrder[categoryA] ?? Number.MAX_VALUE;
        const orderB = categoryOrder[categoryB] ?? Number.MAX_VALUE;

        if (orderA !== orderB) return orderA - orderB;
        return a.localeCompare(b);
    };

    const mergedMealsSummary = {};
    Object.keys(mealsSummary).forEach((date) => {
        if (!mergedMealsSummary[date]) {
            mergedMealsSummary[date] = {};
        }
        Object.entries(mealsSummary[date]).forEach(([mealName, quantity]) => {
            if (mergedMealsSummary[date][mealName]) {
                mergedMealsSummary[date][mealName] += quantity;
            } else {
                mergedMealsSummary[date][mealName] = quantity;
            }
        });
    });

    // Et modifier le tri des dates
    const sortedDates = Object.keys(mergedMealsSummary).sort((a, b) => {
        return parseFrenchDate(a) - parseFrenchDate(b);
    });

    const totalMealsCount = sortedDates.reduce((total, date) => {
        const mealsForDate = mergedMealsSummary[date];
        const dateTotal = Object.values(mealsForDate).reduce((acc, quantity) => acc + quantity, 0);
        return total + dateTotal;
    }, 0);


    const exportToExcel = async () => {
        if (!mergedMealsSummary || Object.keys(mergedMealsSummary).length === 0) {
            toast({
                title: "Erreur",
                description: "Aucune donnée à exporter",
                status: "error",
                duration: 3000,
                isClosable: true,
            });
            return;
        }

        try {
            // Créer un nouveau workbook
            const workbook = new ExcelJS.Workbook();
            workbook.creator = 'AJScanRepas';
            workbook.created = new Date();

            // Fonction pour obtenir la couleur du type de plat
            const getMealTypeColor = (type) => {
                const colors = {
                    'entree': 'E6E6FA',
                    'plat principal': 'DEEAF6',
                    'accompagnement': 'FCE4D6',
                    'fromage': 'FFE4E1',
                    'dessert': 'FFF9C4',
                    'diner': 'FFEBEE'
                };
                return colors[type.toLowerCase()] || 'FFFFFF';
            };

            // Trier les dates
            const dates = Object.keys(mergedMealsSummary).sort((a, b) => {
                const dayOrder = {
                    'lundi': 1, 'mardi': 2, 'mercredi': 3, 'jeudi': 4,
                    'vendredi': 5, 'samedi': 6, 'dimanche': 7
                };
                return (dayOrder[a.toLowerCase()] || 8) - (dayOrder[b.toLowerCase()] || 8);
            });

            // Créer la feuille principale
            const worksheet = workbook.addWorksheet('Menu de la semaine');

            // Définir les colonnes
            const columns = [
                { header: ' ', width: 18 }
            ];
            dates.forEach(date => {
                columns.push(
                    { header: date, width: 22 },
                    { header: 'Qté', width: 6 }
                );
            });
            worksheet.columns = columns;

            // Styles communs
            const borderStyle = {
                top: { style: 'thin' },
                left: { style: 'thin' },
                bottom: { style: 'thin' },
                right: { style: 'thin' }
            };

            // Style pour l'en-tête
            const headerRow = worksheet.getRow(1);
            headerRow.eachCell((cell) => {
                cell.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: '4F81BD' }
                };
                cell.font = {
                    bold: true,
                    color: { argb: 'FFFFFF' }
                };
                cell.alignment = {
                    vertical: 'middle',
                    horizontal: 'center',
                    wrapText: true
                };
                cell.border = borderStyle;
            });

            // Organiser les données par type de plat
            const mealsByType = {};
            Object.entries(mergedMealsSummary).forEach(([date, meals]) => {
                Object.entries(meals).forEach(([fullMealName, quantity]) => {
                    const [type, ...nameParts] = fullMealName.split(' - ');
                    if (!mealsByType[type]) {
                        mealsByType[type] = {};
                    }
                    if (!mealsByType[type][date]) {
                        mealsByType[type][date] = [];
                    }
                    mealsByType[type][date].push({
                        name: nameParts.join(' - '),
                        quantity
                    });
                });
            });

            // Ajouter les données
            let currentRow = 2;
            const mealOrder = getMealOrder();

            Object.keys(mealOrder)
                .sort((a, b) => mealOrder[a] - mealOrder[b])
                .forEach(mealType => {
                    const maxMeals = dates.reduce((max, date) => {
                        const mealsCount = mealsByType[mealType]?.[date]?.length || 0;
                        return Math.max(max, mealsCount);
                    }, 0);

                    if (maxMeals > 0) {
                        for (let mealIndex = 0; mealIndex < maxMeals; mealIndex++) {
                            const row = worksheet.getRow(currentRow);
                            let colIndex = 1;

                            // Type de plat
                            const typeCell = row.getCell(colIndex);
                            typeCell.value = mealType;
                            typeCell.fill = {
                                type: 'pattern',
                                pattern: 'solid',
                                fgColor: { argb: getMealTypeColor(mealType) }
                            };
                            typeCell.border = borderStyle;

                            // Plats et quantités pour chaque date
                            dates.forEach(date => {
                                const meals = mealsByType[mealType]?.[date] || [];
                                const meal = meals[mealIndex];

                                // Nom du plat
                                colIndex++;
                                const nameCell = row.getCell(colIndex);
                                nameCell.value = meal ? meal.name : '';
                                nameCell.border = borderStyle;
                                nameCell.alignment = {
                                    vertical: 'middle',
                                    wrapText: true
                                };

                                // Quantité
                                colIndex++;
                                const quantityCell = row.getCell(colIndex);
                                quantityCell.value = meal ? meal.quantity : '';
                                quantityCell.fill = {
                                    type: 'pattern',
                                    pattern: 'solid',
                                    fgColor: { argb: 'E2EFDA' }
                                };
                                quantityCell.border = borderStyle;
                                quantityCell.alignment = {
                                    vertical: 'middle',
                                    horizontal: 'center'
                                };
                            });

                            currentRow++;
                        }
                    } else {
                        const row = worksheet.getRow(currentRow);
                        row.getCell(1).value = mealType;
                        row.eachCell(cell => {
                            cell.border = borderStyle;
                        });
                        currentRow++;
                    }
                });

            // Créer la feuille de résumé
            const summarySheet = workbook.addWorksheet('Résumé');
            summarySheet.columns = [
                { header: 'Jour', width: 15 },
                { header: 'Type de plat', width: 20 },
                { header: 'Nom du plat', width: 40 },
                { header: 'Quantité', width: 12 }
            ];

            // Style de l'en-tête du résumé
            const summaryHeaderRow = summarySheet.getRow(1);
            summaryHeaderRow.eachCell((cell) => {
                cell.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: '4F81BD' }
                };
                cell.font = {
                    bold: true,
                    color: { argb: 'FFFFFF' }
                };
                cell.border = borderStyle;
                cell.alignment = {
                    vertical: 'middle',
                    horizontal: 'center'
                };
            });

            // Ajouter les données du résumé
            let summaryRow = 2;
            dates.forEach(date => {
                Object.entries(mergedMealsSummary[date])
                    .sort(([mealA], [mealB]) => {
                        const [typeA] = mealA.split(' - ');
                        const [typeB] = mealB.split(' - ');
                        return (mealOrder[typeA] || 99) - (mealOrder[typeB] || 99);
                    })
                    .forEach(([fullMealName, quantity]) => {
                        const [type, ...nameParts] = fullMealName.split(' - ');
                        const row = summarySheet.getRow(summaryRow);

                        row.getCell(1).value = date;
                        row.getCell(2).value = type;
                        row.getCell(3).value = nameParts.join(' - ');
                        row.getCell(4).value = quantity;

                        row.eachCell(cell => {
                            cell.border = borderStyle;
                            cell.alignment = {
                                vertical: 'middle',
                                wrapText: true
                            };
                        });

                        summaryRow++;
                    });
            });

            // Sauvegarder le fichier
            const buffer = await workbook.xlsx.writeBuffer();
            const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = `Commandes_Repas_${new Date().toISOString().split('T')[0]}.xlsx`;
            a.click();
            window.URL.revokeObjectURL(url);

            toast({
                title: "Succès",
                description: "Le fichier Excel a été généré avec succès",
                status: "success",
                duration: 3000,
                isClosable: true,
            });

        } catch (error) {
            console.error('Erreur lors de l\'export Excel:', error);
            toast({
                title: "Erreur",
                description: "Une erreur est survenue lors de la génération du fichier Excel",
                status: "error",
                duration: 3000,
                isClosable: true,
            });
        }
    };

    const generateEmailBody = () => {
        let body = "Voici le total des repas commandés :\n\n";
        sortedDates.forEach((date) => {
            body += `Date: ${date}\n`;

            body += '\nDétail des plats :\n';
            Object.entries(mergedMealsSummary[date])
                .sort(([mealA], [mealB]) => {
                    const categoryA = mealA.split(' - ')[0];
                    const categoryB = mealB.split(' - ')[0];
                    const order = getMealOrder();
                    const orderA = order[categoryA] ?? 99;
                    const orderB = order[categoryB] ?? 99;
                    if (orderA !== orderB) return orderA - orderB;
                    return mealA.localeCompare(mealB);
                })
                .forEach(([meal, quantity]) => {
                    body += `- ${meal}: ${quantity} plat(s)\n`;
                });
            body += '\n';
        });
        body += `\nTotal global des repas commandés : ${totalMealsCount}`;
        return body;
    };

    const copyToClipboard = async () => {
        setIsCopying(true);
        const emailBody = generateEmailBody();

        try {
            await navigator.clipboard.writeText(emailBody);
            toast({
                title: "Succès !",
                description: "Le texte a été copié dans le presse-papiers.",
                status: "success",
                duration: 3000,
                isClosable: true,
            });
        } catch (error) {
            try {
                const textarea = document.createElement('textarea');
                textarea.value = emailBody;
                document.body.appendChild(textarea);
                textarea.select();
                document.execCommand('copy');
                document.body.removeChild(textarea);
                toast({
                    title: "Succès !",
                    description: "Le texte a été copié dans le presse-papiers.",
                    status: "success",
                    duration: 3000,
                    isClosable: true,
                });
            } catch (fallbackError) {
                toast({
                    title: "Erreur",
                    description: "Le texte n'a pas pu être copié. Veuillez réessayer.",
                    status: "error",
                    duration: 3000,
                    isClosable: true,
                });
            }
        } finally {
            setIsCopying(false);
        }
    };

    const downloadPDF = () => {
        const doc = new jsPDF();
        const logoUrl = `${process.env.PUBLIC_URL}/AJScanRepasLogo512.jpg`;
        const image = new Image();
        image.src = logoUrl;

        image.onload = function () {
            const logoWidth = 50;
            const logoHeight = 50;
            doc.addImage(image, 'JPEG', 10, 0, logoWidth, logoHeight);

            doc.setFont("helvetica", "bold");
            doc.setFontSize(18);

            const pageWidth = doc.internal.pageSize.getWidth();
            let yPosition = logoHeight + 0;

            doc.text("Total des repas commandés", pageWidth / 2, yPosition, { align: 'center' });

            yPosition += 15;

            doc.setFont("helvetica", "normal");
            doc.setFontSize(10);

            const categoryOrder = getMealOrder();

            sortedDates.forEach((date) => {
                doc.setFont("helvetica", "bold");
                doc.text(`${date.toUpperCase()}`, 10, yPosition);
                yPosition += 8;

                doc.setFont("helvetica", "normal");
                Object.entries(mergedMealsSummary[date])
                    .sort(([mealA], [mealB]) => {
                        const categoryA = mealA.split(' - ')[0];
                        const categoryB = mealB.split(' - ')[0];
                        const orderA = categoryOrder[categoryA] ?? Number.MAX_VALUE;
                        const orderB = categoryOrder[categoryB] ?? Number.MAX_VALUE;
                        if (orderA !== orderB) return orderA - orderB;
                        return mealA.localeCompare(mealB);
                    })
                    .forEach(([meal, quantity]) => {
                        doc.text(`- ${meal}: ${quantity} plat(s)`, 10, yPosition);
                        yPosition += 7;
                        if (yPosition > 280) {
                            doc.addPage();
                            yPosition = 10;
                        }
                    });

                yPosition += 5;
            });

            doc.setFont("helvetica", "bold");
            doc.setFontSize(14);
            doc.text(`Nombre total de plats commandés : ${totalMealsCount}`, 10, yPosition + 10);

            doc.save('TotalRepasCommandes.pdf');
        };
    };

    const mealOrder = getMealOrder(data);

    return (
        <Card borderRadius="xl" boxShadow="xl" bg="white" overflow="hidden"
            w="100%" maxWidth={{ base: '100%', lg: "1200px" }} mt={6}
        >
            <CardHeader bg="teal.50" borderBottom="2px" borderColor="teal.100">
                <VStack spacing={4}>
                    <Stack
                        direction={{ base: "column", md: "row" }}
                        w="full"
                        spacing={4}
                        justify="space-between"
                        align={{ base: "center", md: "center" }}
                    >
                        {/* Titre avec icône */}
                        <HStack spacing={3} justify={{ base: "center", md: "flex-start" }}>
                            <CalendarIcon w={6} h={6} color="teal.500" />
                            <Heading
                                size="lg"
                                color="teal.700"
                                textAlign={{ base: "center", md: "left" }}
                            >
                                Récapitulatif des Commandes
                            </Heading>
                        </HStack>

                        {/* Badge avec tooltip */}
                        <Tooltip label="Nombre total de tables analysées">
                            <Badge
                                colorScheme="teal"
                                p={2}
                                borderRadius="md"
                                fontSize="md"
                            >
                                {tableCount} tableau{tableCount > 1 ? 'x' : ''}
                            </Badge>
                        </Tooltip>
                    </Stack>

                    <StatGroup
                        w="full"
                        bg="white"
                        p={4}
                        borderRadius="lg"
                        boxShadow="sm"
                    >
                        <Stat>
                            <StatLabel>Total Plats</StatLabel>
                            <StatNumber>{totalMealsCount}</StatNumber>
                        </Stat>
                        <Stat>
                            <StatLabel>Jours</StatLabel>
                            <StatNumber>{sortedDates.length}</StatNumber>
                        </Stat>
                        <Stat>
                            <StatLabel>Moyenne/Jour</StatLabel>
                            <StatNumber>
                                {sortedDates.length > 0 ?
                                    Math.round(totalMealsCount / sortedDates.length) : 0}
                            </StatNumber>
                        </Stat>
                    </StatGroup>
                </VStack>
            </CardHeader>

            <CardBody pt={6} px={2}>
                {sortedDates.length === 0 ? (
                    <Card bg="gray.50" p={6}>
                        <Text fontSize="lg" textAlign="center" color="gray.500">
                            Aucune commande enregistrée
                        </Text>
                    </Card>
                ) : (
                    <Accordion allowMultiple defaultIndex={[0]}>
                        {sortedDates.map((date, index) => (
                            <AccordionItem
                                key={index}
                                border="none"
                                mb={4}
                            >
                                <AccordionButton
                                    bg="gray.50"
                                    _hover={{ bg: 'gray.100' }}
                                    borderRadius="lg"
                                >
                                    <HStack flex="1" justify="space-between">
                                        <HStack>
                                            <Text
                                                fontSize="lg"
                                                fontWeight="bold"
                                                color="teal.700"
                                                textTransform="capitalize"
                                            >
                                                {date.toLowerCase()}
                                            </Text>
                                            <Badge colorScheme="teal">
                                                {Object.values(mergedMealsSummary[date]).reduce((a, b) => a + b, 0)} plats
                                            </Badge>
                                        </HStack>
                                        <AccordionIcon />
                                    </HStack>
                                </AccordionButton>

                                <AccordionPanel pb={4}>
                                    <SimpleGrid columns={{ base: 1, lg: 2 }} spacing={6} mt={4}>
                                        <Card variant="outline">
                                            <CardHeader bg="blue.50" py={2}>
                                                <HStack>
                                                    <InfoIcon color="blue.500" />
                                                    <Text fontWeight="bold" color="blue.700">
                                                        Statistiques par type
                                                    </Text>
                                                </HStack>
                                            </CardHeader>
                                            <CardBody>
                                                <VStack align="stretch" spacing={3}>
                                                    {Object.entries(dishTypeStats[date] || {})
                                                        .sort(([categoryA], [categoryB]) => {
                                                            const order = getMealOrder();
                                                            return (order[categoryA] ?? 99) - (order[categoryB] ?? 99);
                                                        })
                                                        .map(([category, data], idx) => (
                                                            <HStack
                                                                key={idx}
                                                                justify="space-between"
                                                                p={2}
                                                                bg={idx % 2 === 0 ? 'gray.50' : 'white'}
                                                                borderRadius="md"
                                                            >
                                                                <Text fontSize="sm" textTransform="capitalize">
                                                                    {category}
                                                                </Text>
                                                                <Badge
                                                                    colorScheme={data.count != data.total ? "orange" : "green"}
                                                                >
                                                                    {data.count}/{data.total}
                                                                </Badge>
                                                            </HStack>
                                                        ))}
                                                </VStack>
                                            </CardBody>
                                        </Card>

                                        <Card variant="outline">
                                            <CardHeader bg="purple.50" py={2}>
                                                <HStack>
                                                    <InfoIcon color="purple.500" />
                                                    <Text fontWeight="bold" color="purple.700">
                                                        Détail des plats
                                                    </Text>
                                                </HStack>
                                            </CardHeader>
                                            <CardBody>
                                                <VStack align="stretch" spacing={3}>
                                                    {Object.entries(mergedMealsSummary[date])
                                                        .sort(([mealA], [mealB]) => {
                                                            const categoryA = mealA.split(' - ')[0];
                                                            const categoryB = mealB.split(' - ')[0];
                                                            const order = getMealOrder();
                                                            const orderA = order[categoryA] ?? 99;
                                                            const orderB = order[categoryB] ?? 99;
                                                            if (orderA !== orderB) return orderA - orderB;
                                                            return mealA.localeCompare(mealB);
                                                        })
                                                        .map(([meal, quantity], idx) => (
                                                            <Flex
                                                                key={idx}
                                                                justify="space-between"
                                                                align="center"
                                                                p={2}
                                                                bg={idx % 2 === 0 ? 'gray.50' : 'white'}
                                                                borderRadius="md"
                                                            >
                                                                <Text fontSize="sm" noOfLines={2}>
                                                                    {meal}
                                                                </Text>
                                                                <Badge colorScheme="green" ml={2}>
                                                                    {quantity}
                                                                </Badge>
                                                            </Flex>
                                                        ))}
                                                </VStack>
                                            </CardBody>
                                        </Card>
                                    </SimpleGrid>
                                </AccordionPanel>
                            </AccordionItem>
                        ))}
                    </Accordion>
                )}

                <Divider my={6} />

                <SimpleGrid columns={{ base: 1, md: 3 }} spacing={4}>
                    <Button
                        leftIcon={<EmailIcon />}
                        colorScheme="blue"
                        size="lg"
                        onClick={copyToClipboard}
                        isLoading={isCopying}
                        loadingText="Copie..."
                    >
                        Copier pour email
                    </Button>

                    <Button
                        leftIcon={<DownloadIcon />}
                        colorScheme="teal"
                        size="lg"
                        onClick={downloadPDF}
                    >
                        Télécharger PDF
                    </Button>

                    <Button
                        leftIcon={<ExternalLinkIcon />}
                        colorScheme="green"
                        size="lg"
                        onClick={exportToExcel}
                    >
                        Exporter Excel
                    </Button>
                </SimpleGrid>
            </CardBody>
        </Card>
    );
};

export default TotalMeals;