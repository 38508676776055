import React from 'react';
import {
    Box,
    Flex,
    Table,
    Tbody,
    Tr,
    Td,
    Th,
    Thead,
    Heading,
    Text,
    Icon,
    Badge,
    Tooltip,
    Grid
} from '@chakra-ui/react';
import { CheckIcon, InfoIcon, WarningIcon } from '@chakra-ui/icons';

const ResultsDisplay = ({ data }) => {
    if (!Array.isArray(data) || data.length === 0) {
        return (
            <Flex
                direction="column"
                align="center"
                justify="center"
                p={8}
                bg="red.50"
                borderRadius="lg"
            >
                <WarningIcon w={8} h={8} color="red.500" mb={4} />
                <Text fontSize="lg" color="red.500" fontWeight="medium">
                    Aucune donnée à afficher
                </Text>
            </Flex>
        );
    }

    // Fonction pour déterminer le type de repas
    const getMealType = (cellContent, cellIndex) => {
        // Ne traiter que les deux premières colonnes (index 0 et 1)
        if (cellIndex > 1 || typeof cellContent !== 'string') return 'other';

        const lowerContent = cellContent.toLowerCase();
        if (lowerContent.includes('entrée')) return 'starter';
        if (lowerContent.includes('plat principal')) return 'main';
        if (lowerContent.includes('accompagnement')) return 'side';
        if (lowerContent.includes('fromage')) return 'cheese';
        if (lowerContent.includes('dessert')) return 'dessert';
        return 'other';
    };

    // Fonction pour styliser la cellule en fonction du type de repas
    const getCellStyle = (cellContent, cellIndex, isSelected, isLeftOfSelected, isNumber) => {
        const baseStyle = {
            border: "1px solid",
            borderColor: "gray.200",
            transition: "all 0.2s",
            position: "relative"
        };

        // Traiter d'abord le cas des cellules avec des nombres
        if (isNumber) {
            return {
                ...baseStyle,
                bg: "orange.50", // Changement de la couleur pour les nombres
                _hover: { bg: "orange.100" },
                boxShadow: "sm"
            };
        }

        // Traiter ensuite le cas des cellules sélectionnées
        if (isLeftOfSelected) {
            return {
                ...baseStyle,
                bg: "green.50",
                _hover: { bg: "green.100" },
                boxShadow: "sm"
            };
        }

        // Traiter enfin les types de repas pour les deux premières colonnes
        const mealType = getMealType(cellContent, cellIndex);
        switch (mealType) {
            case 'starter':
                return { ...baseStyle, bg: "blue.50" };
            case 'main':
                return { ...baseStyle, bg: "purple.50" };
            case 'side':
                return { ...baseStyle, bg: "orange.50" };
            case 'cheese':
                return { ...baseStyle, bg: "yellow.50" };
            case 'dessert':
                return { ...baseStyle, bg: "pink.50" };
            default:
                return baseStyle;
        }
    };

    return (
        <Box
            display={{ base: "none", lg: "none", xl: "block" }}
            bg="white"
            pt={6}
            px={6}
            borderRadius="xl"
            boxShadow="lg"
            mt={5}
            mx="auto"
            overflowX="auto"
            maxW="100%"
        >
            <Flex
                justifyContent="space-between"
                alignItems="center"
                mb={6}
                borderBottom="2px"
                borderColor="teal.100"
                pb={4}
            >
                <Heading as="h2" size="lg" color="teal.700">
                    Résultats du tableau
                </Heading>
                <Badge
                    colorScheme="teal"
                    p={2}
                    borderRadius="md"
                    fontSize="md"
                >
                    tableau analysé
                </Badge>
            </Flex>

            {data.map((table, index) => (
                <Box
                    key={index}
                    mb={6}
                    bg="white"
                    borderRadius="lg"
                    boxShadow="md"
                    overflow="hidden"
                >
                    <Flex
                        bg="teal.50"
                        p={4}
                        alignItems="center"
                        borderBottom="2px"
                        borderColor="teal.100"
                    >
                        <Heading as="h3" size="md" color="teal.700">
                            Tableau
                        </Heading>
                        <InfoIcon ml={2} color="teal.500" />
                    </Flex>

                    <Box overflowX="auto">
                        <Table variant="simple" size="xs">
                            <Thead bg="gray.50">
                                <Tr>
                                    {table[0]?.map((header, headerIndex) => (
                                        <Th
                                            key={headerIndex}
                                            py={2}
                                            textAlign="center"
                                            color="gray.700"
                                            fontSize="xs"
                                            textTransform="uppercase"
                                            borderBottom="2px"
                                            borderColor="teal.100"
                                        >
                                            {header}
                                        </Th>
                                    ))}
                                </Tr>
                            </Thead>
                            <Tbody>
                                {table.slice(1).map((row, rowIndex) => (
                                    <Tr key={rowIndex}>
                                        {row.map((cell, cellIndex) => {
                                            const isSelected = typeof cell === 'string' && cell.includes("SELECTED,");
                                            const isLeftOfSelected = cellIndex + 1 < row.length &&
                                                typeof row[cellIndex + 1] === 'string' &&
                                                row[cellIndex + 1].includes("SELECTED,");
                                            const isNumber = !isNaN(parseFloat(cell)) && cell !== '';
                                            const isLeftOfNumber = cellIndex + 1 < row.length &&
                                                !isNaN(parseFloat(row[cellIndex + 1])) &&
                                                row[cellIndex + 1] !== '';

                                            return (
                                                <Td
                                                    key={cellIndex}
                                                    {...getCellStyle(cell, cellIndex, isSelected, isLeftOfSelected || isLeftOfNumber, isNumber)}
                                                    px={1}
                                                    py={1}
                                                    fontSize="sm"
                                                >
                                                    <Tooltip
                                                        label={isSelected ? "Sélectionné" : cell}
                                                        placement="top"
                                                        hasArrow
                                                    >
                                                        <Box>
                                                            {isSelected ? (
                                                                <Flex justify="center">
                                                                    <Icon
                                                                        as={CheckIcon}
                                                                        color="green.500"
                                                                        w={5}
                                                                        h={5}
                                                                        p={1}
                                                                        bg="green.100"
                                                                        borderRadius="full"
                                                                    />
                                                                </Flex>
                                                            ) : isNumber ? (
                                                                <Badge
                                                                    colorScheme="orange"
                                                                    py={1}
                                                                    px={1}
                                                                    borderRadius="md"
                                                                    width="100%"
                                                                    textAlign="center"
                                                                    fontSize="sm"
                                                                >
                                                                    {cell}
                                                                </Badge>
                                                            ) : (
                                                                <Text>{cell}</Text>
                                                            )}
                                                        </Box>
                                                    </Tooltip>
                                                </Td>
                                            );
                                        })}
                                    </Tr>
                                ))}
                            </Tbody>
                        </Table>
                    </Box>
                </Box>
            ))}
        </Box>
    );
};

export default ResultsDisplay;