import React, { useState, useEffect } from 'react';
import { Box, Spinner, Text, Center } from '@chakra-ui/react';
import { Viewer, SpecialZoomLevel, Worker } from '@react-pdf-viewer/core';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';

import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';

const PDFPreview = ({ pdfFile, pageNumber, rotation, onRotate }) => {
    const [loading, setLoading] = useState(true);
    const [pdfUrl, setPdfUrl] = useState(null);
    const defaultLayoutPluginInstance = defaultLayoutPlugin();

    useEffect(() => {
        if (pdfFile) {
            const url = URL.createObjectURL(pdfFile);
            setPdfUrl(url);
            return () => URL.revokeObjectURL(url);
        }
    }, [pdfFile]);

    if (!pdfUrl) {
        return (
            <Center h="600px">
                <Spinner size="xl" color="teal.500" thickness="4px" />
            </Center>
        );
    }

    return (
        <Box height="600px" width="100%" position="relative">
            {loading && (
                <Center position="absolute" top="0" left="0" right="0" bottom="0" bg="white" zIndex="1">
                    <Spinner size="xl" color="teal.500" thickness="4px" />
                </Center>
            )}

            <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
                <Viewer
                    fileUrl={pdfUrl}
                    plugins={[defaultLayoutPluginInstance]}
                    defaultScale={SpecialZoomLevel.PageFit}
                    initialPage={pageNumber - 1}
                    rotation={rotation}
                    onRotate={(degrees) => onRotate(() => degrees)}
                    onDocumentLoad={() => setLoading(false)}
                    renderError={(error) => (
                        <Center h="100%">
                            <Text color="red.500" fontSize="lg">
                                Une erreur est survenue lors du chargement du PDF
                            </Text>
                        </Center>
                    )}
                />
            </Worker>
        </Box>
    );
};

export default PDFPreview;