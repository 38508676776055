import React, { useState, useEffect, useMemo } from 'react';
import {
    Box,
    Button,
    Flex,
    Heading,
    Text,
    IconButton,
    Input,
    useToast,
    Badge,
    Collapse,
    VStack,
    HStack,
    Divider,
    Tooltip,
    Card,
    CardHeader,
    CardBody,
    SimpleGrid
} from '@chakra-ui/react';
import {
    CloseIcon,
    ChevronDownIcon,
    ChevronUpIcon,
    CalendarIcon,
    EditIcon,
    DeleteIcon,
    CheckIcon,
    RepeatIcon
} from '@chakra-ui/icons';
import { useTableCount } from '../../contexts/TableCountContext';

const MealSummary = ({ data, onValidate }) => {
    const [history, setHistory] = useState([]);
    const [expandedDates, setExpandedDates] = useState({});
    const [editMode, setEditMode] = useState({});
    const toast = useToast();
    const { incrementTableCount, decrementTableCount } = useTableCount();

    // Fonction pour résumer les repas par date
    const summarizeMealsByDate = (tableData) => {
        const summaryByDate = {};
        const dates = tableData[0][0].slice(2).filter((_, index) => index % 2 === 0);

        tableData.forEach((table) => {
            for (let rowIndex = 1; rowIndex < table.length; rowIndex++) {
                const row = table[rowIndex];
                const mealType = row[0];
                const mealCategory = row[1];

                for (let i = 2; i < row.length; i += 2) {
                    const dateIndex = Math.floor((i - 2) / 2);
                    const date = dates[dateIndex];
                    const mealName = row[i];
                    const quantityCell = row[i + 1];
                    const quantity = quantityCell === 'SELECTED,' ? 1 : parseFloat(quantityCell);

                    if (!isNaN(quantity) && quantity > 0) {
                        if (!summaryByDate[date]) {
                            summaryByDate[date] = [];
                        }

                        summaryByDate[date].push({
                            meal: `${mealType.toLowerCase()} - ${mealCategory.toLowerCase()} - ${mealName.toLowerCase()}`,
                            quantity,
                        });
                    }
                }
            }
        });

        return summaryByDate;
    };

    const [mealSummaryByDate, setMealSummaryByDate] = useState(summarizeMealsByDate(data));

    useEffect(() => {
        setMealSummaryByDate(summarizeMealsByDate(data));
    }, [data]);

    const handleMealValidation = (meals) => {
        setHistory([...history, meals]);
        onValidate(meals);
        incrementTableCount();

        toast({
            title: 'Ajout réussi',
            description: 'Les repas ont été ajoutés.',
            status: 'success',
            duration: 3000,
            isClosable: true,
        });
    };

    const handleUndoLastAction = () => {
        if (history.length > 0) {
            const newHistory = [...history];
            const lastEntry = newHistory.pop();

            setHistory(newHistory);
            decrementTableCount();

            const updatedMeals = {};
            Object.keys(lastEntry).forEach((date) => {
                if (!updatedMeals[date]) {
                    updatedMeals[date] = [];
                }
                lastEntry[date].forEach((meal) => {
                    updatedMeals[date].push({
                        meal: meal.meal,
                        quantity: -meal.quantity,
                    });
                });
            });

            onValidate(updatedMeals);

            toast({
                title: 'Annulation réussie',
                description: 'Les repas ont été retirés.',
                status: 'success',
                duration: 3000,
                isClosable: true,
            });
        }
    };

    const handleRemoveMeal = (date, mealIndex) => {
        const updatedSummary = { ...mealSummaryByDate };
        updatedSummary[date].splice(mealIndex, 1);

        if (updatedSummary[date].length === 0) {
            delete updatedSummary[date];
        }

        setMealSummaryByDate(updatedSummary);
    };

    const handleRemoveDate = (date) => {
        const updatedSummary = { ...mealSummaryByDate };
        delete updatedSummary[date];
        setMealSummaryByDate(updatedSummary);
    };

    const handleQuantityChange = (date, mealIndex, newQuantity) => {
        const updatedSummary = { ...mealSummaryByDate };
        const parsedQuantity = parseFloat(newQuantity);
        if (!isNaN(parsedQuantity) && parsedQuantity >= 0) {
            updatedSummary[date][mealIndex].quantity = parsedQuantity;
            setMealSummaryByDate(updatedSummary);
        }
    };

    const toggleDateExpansion = (date) => {
        setExpandedDates(prev => ({
            ...prev,
            [date]: !prev[date]
        }));
    };

    const toggleEditMode = (date) => {
        setEditMode(prev => ({
            ...prev,
            [date]: !prev[date]
        }));
    };

    // Ajouter cette fonction dans le composant
    const getTotalMealsForDate = (date) => {
        return mealSummaryByDate[date].reduce((total, item) => {
            return total + item.quantity;
        }, 0);
    };

    const getMealTypeColor = (mealType) => {
        const type = mealType.split(' - ')[0];
        switch (type) {
            case 'déjeuner': return 'blue';
            case 'diner': return 'purple';
            default: return 'gray';
        }
    };

    const getDayOrder = (date) => {
        const dayOrder = {
            'lundi': 1,
            'mardi': 2,
            'mercredi': 3,
            'jeudi': 4,
            'vendredi': 5,
            'samedi': 6,
            'dimanche': 7
        };

        const day = date.split(' ')[0].toLowerCase();
        return dayOrder[day] || 8;
    };

    const sortedDates = useMemo(() => {
        return Object.keys(mealSummaryByDate).sort((a, b) => getDayOrder(a) - getDayOrder(b));
    }, [mealSummaryByDate]);

    return (
        <Box
            bg="white"
            p={2}
            borderRadius="xl"
            boxShadow="xl"
            mt={5}
            width="100%"
            maxWidth={{ base: '95vw', lg: "1200px" }}
        >
            <VStack spacing={6} align="stretch">
                <Flex
                    justifyContent="space-between"
                    alignItems="center"
                    borderBottom="2px"
                    borderColor="teal.100"
                    pb={4}
                >
                    <HStack spacing={3}>
                        <CalendarIcon color="teal.500" w={6} h={6} />
                        <Heading as="h2" size="lg" color="teal.700">
                            Résumé des Commandes
                        </Heading>
                    </HStack>
                    <Badge
                        colorScheme="teal"
                        p={2}
                        borderRadius="md"
                        fontSize="md"
                    >
                        {sortedDates.length} jours
                    </Badge>
                </Flex>

                {sortedDates.length === 0 ? (
                    <Card bg="gray.50" p={6} borderRadius="lg">
                        <Text fontSize="lg" color="gray.500" textAlign="center">
                            Aucun plat sélectionné pour le moment
                        </Text>
                    </Card>
                ) : (
                    <SimpleGrid columns={{ base: 1, lg: 1 }} spacing={4}>
                        {sortedDates.map((date, index) => (
                            <Card
                                key={index}
                                borderRadius="lg"
                                overflow="hidden"
                                variant="outline"
                                borderColor="gray.200"
                                transition="all 0.2s"
                                _hover={{ boxShadow: "md" }}
                            >
                                <CardHeader
                                    bg={editMode[date] ? "orange.50" : "gray.50"}
                                    p={2}
                                    borderBottom="1px"
                                    borderColor="gray.200"
                                >
                                    <Flex justify="space-between" align="center">
                                        <HStack>
                                            <Text
                                                fontSize="lg"
                                                fontWeight="bold"
                                                color="teal.700"
                                                textTransform="capitalize"
                                            >
                                                {date.toLowerCase()}
                                            </Text>
                                            <Badge colorScheme="teal" ml={1} py={1} px={2} borderRadius="lg">
                                                {getTotalMealsForDate(date)} plat{getTotalMealsForDate(date) > 1 ? 's' : ''}
                                            </Badge>
                                        </HStack>
                                        <HStack spacing={1}>
                                            <Tooltip label={editMode[date] ? "Terminer l'édition" : "Éditer"}>
                                                <IconButton
                                                    size="md"
                                                    icon={<EditIcon />}
                                                    colorScheme={editMode[date] ? "orange" : "teal"}
                                                    variant="ghost"
                                                    onClick={() => toggleEditMode(date)}
                                                />
                                            </Tooltip>
                                            <Tooltip label="Supprimer la journée">
                                                <IconButton
                                                    size="md"
                                                    icon={<DeleteIcon />}
                                                    colorScheme="red"
                                                    variant="ghost"
                                                    onClick={() => handleRemoveDate(date)}
                                                />
                                            </Tooltip>
                                            <IconButton
                                                size="md"
                                                icon={expandedDates[date] ? <ChevronUpIcon /> : <ChevronDownIcon />}
                                                variant="ghost"
                                                onClick={() => toggleDateExpansion(date)}
                                            />
                                        </HStack>
                                    </Flex>
                                </CardHeader>

                                <Collapse in={expandedDates[date]} animateOpacity>
                                    <CardBody p={4}>
                                        <VStack spacing={3} align="stretch">
                                            {mealSummaryByDate[date].map((item, itemIndex) => (
                                                <Flex
                                                    key={itemIndex}
                                                    p={3}
                                                    bg={itemIndex % 2 === 0 ? 'gray.50' : 'white'}
                                                    borderRadius="md"
                                                    justify="space-between"
                                                    align="center"
                                                    transition="all 0.2s"
                                                    _hover={{ bg: 'gray.100' }}
                                                >
                                                    <HStack spacing={4} flex={1}>
                                                        <Badge
                                                            colorScheme={getMealTypeColor(item.meal)}
                                                            px={2}
                                                            py={1}
                                                        >
                                                            {item.meal.split(' - ')[0]}
                                                        </Badge>
                                                        <Text
                                                            color="gray.700"
                                                            fontSize="sm"
                                                            fontWeight="medium"
                                                        >
                                                            {item.meal.split(' - ').slice(1).join(' - ')}
                                                        </Text>
                                                    </HStack>
                                                    <HStack spacing={4}>
                                                        {editMode[date] ? (
                                                            <Input
                                                                value={item.quantity}
                                                                size="sm"
                                                                width="70px"
                                                                textAlign="center"
                                                                onChange={(e) => handleQuantityChange(date, itemIndex, e.target.value)}
                                                                type="number"
                                                                min="0"
                                                            />
                                                        ) : (
                                                            <Badge
                                                                colorScheme="green"
                                                                fontSize="sm"
                                                                px={3}
                                                                py={1}
                                                            >
                                                                {item.quantity}
                                                            </Badge>
                                                        )}
                                                        {editMode[date] && (
                                                            <IconButton
                                                                aria-label="Supprimer le plat"
                                                                icon={<CloseIcon />}
                                                                size="xs"
                                                                colorScheme="red"
                                                                variant="ghost"
                                                                onClick={() => handleRemoveMeal(date, itemIndex)}
                                                            />
                                                        )}
                                                    </HStack>
                                                </Flex>
                                            ))}
                                        </VStack>
                                    </CardBody>
                                </Collapse>
                            </Card>
                        ))}
                    </SimpleGrid>
                )}

                <Divider my={2} />

                <Flex
                    justifyContent="center"
                    gap={6}
                    direction={{ base: "column", md: "row" }}
                >
                    <Button
                        leftIcon={<CheckIcon />}
                        colorScheme="teal"
                        size="lg"
                        px={8}
                        onClick={() => handleMealValidation(mealSummaryByDate)}
                        isDisabled={sortedDates.length === 0}
                    >
                        Valider les commandes
                    </Button>
                    <Button
                        leftIcon={<RepeatIcon />}
                        colorScheme="gray"
                        size="lg"
                        px={8}
                        onClick={handleUndoLastAction}
                        isDisabled={history.length === 0}
                    >
                        Annuler la dernière action
                    </Button>
                </Flex>
            </VStack>
        </Box>
    );
};

export default MealSummary;