import React from "react";
import {
    Box,
    Image,
    Flex,
    HStack,
    IconButton,
    useDisclosure,
    Stack,
    Link,
    useTheme,
    useColorModeValue, useToast
} from "@chakra-ui/react";
import {
    HamburgerIcon,
    CloseIcon,
} from "@chakra-ui/icons";
import { Link as RouterLink } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
import LogoAJ from "../../assets/images/AJScanRepasLogo.jpg";
import LogoutButton from './LogoutButton'; // Importer ton bouton de déconnexion

const NavLink = ({ children, href, onClick }) => {
    const theme = useTheme();
    const lineColor = useColorModeValue(
        theme.colors.light.bg,
        theme.colors.dark.bg
    );

    return (
        <Link
            className="nav-link"
            position="relative"
            px={3}
            py={2}
            rounded="md"
            as={RouterLink}
            to={href}
            onClick={onClick} // Gestion de l'événement onClick
            _before={{
                content: '""',
                position: "absolute",
                top: "40px", // Position en dessous du texte
                left: 0,
                width: 0,
                height: "2px",
                backgroundColor: lineColor,
                transition: "width 0.4s ease",
            }}
            _hover={{
                _before: {
                    width: "100%",
                },
                textDecoration: "none",
            }}
        >
            {children}
        </Link>
    );
};


const Navbar = ({ onResetScan }) => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const toast = useToast();

    // Modifiez la fonction handleNewSession
    const handleNewSession = () => {
        if (onResetScan) {
            onResetScan();
            toast({
                title: "Session réinitialisée",
                description: "Vous pouvez commencer une nouvelle analyse",
                status: "success",
                duration: 3000,
                isClosable: true,
            });
        }
    };

    return (
        <Box
            id="Navbar"
            bg="white"
            w="100vw"
            px={4}
        >
            <Flex alignItems="center" justifyContent="space-between" py={1}>
                <Link as={RouterLink} to="/" style={{ textDecoration: "none" }}>
                    <Box
                        fontWeight="bold"
                        fontSize="xl"
                        display="flex"
                        alignItems="center"
                    >
                        <Image
                            src={LogoAJ}
                            alt="Logo Aj Services"
                            width={{ base: "150px", md: "200px" }}
                            ml={3}
                            py={1}
                        />
                    </Box>
                </Link>

                <HStack
                    as="nav"
                    spacing={5}
                    fontSize="xl"
                    justifyContent="center"
                    alignItems="center"
                    flex={1}
                    display={{ base: "none", lg: "flex" }}
                    color="green.600"
                >
                    <NavLink href="/ScanRepas" onClick={handleNewSession}>
                        Nouvelle session
                    </NavLink>
                    <NavLink href="/MultiPDFPage">
                        Extraction PDF
                    </NavLink>
                </HStack>

                <Flex alignItems="center">
                    {/* Bouton de déconnexion */}
                    <LogoutButton />

                    <IconButton
                        size="md"
                        icon={isOpen ? <CloseIcon /> : <HamburgerIcon />}
                        aria-label="Open Menu"
                        display={{ lg: "none" }}
                        onClick={isOpen ? onClose : onOpen}
                    />
                </Flex>
            </Flex>

            {isOpen ? (
                <Box py={4} display={{ lg: "none" }}>
                    <Stack
                        as="nav"
                        fontSize="xl"
                        spacing={6}
                        pl={4}
                        color="green.600"
                    >
                        <Link _hover={{ textDecoration: "none" }} onClick={handleNewSession}>
                            Nouvelle session
                        </Link>
                        <Link _hover={{ textDecoration: "none" }} href="/MultiPDFPage">
                            Extraction PDF
                        </Link>
                    </Stack>
                </Box>
            ) : null}
        </Box>
    );
};


export default Navbar;
