import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { ChakraProvider, ColorModeScript } from "@chakra-ui/react";
import theme from "./theme";
import Login from "./components/pages/Login";
import ScanRepas from "./components/pages/ScanRepas";
import MultiPDFPage from "./components/pages/MultiPDFPage";
import ProtectedRoute from "./components/common/ProtectedRoute"; // Import de la route protégée
import { TableCountProvider } from "./contexts/TableCountContext";

function App() {
  return (
    <ChakraProvider theme={theme}>
      <TableCountProvider>
        <ColorModeScript initialColorMode={theme.config.initialColorMode} />
        <Router>
          <Routes>
            {/* Route publique */}
            <Route path="/" element={<Login />} />
            <Route path="/login" element={<Login />} />

            {/* Route protégée */}
            <Route
              path="/ScanRepas"
              element={<ProtectedRoute element={ScanRepas} />}
            />
            {/* Route protégée */}
            <Route
              path="/MultiPDFPage"
              element={<ProtectedRoute element={MultiPDFPage} />}
            />
          </Routes>
        </Router>
      </TableCountProvider>
    </ChakraProvider>
  );
}

export default App;
