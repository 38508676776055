import React, { useState, useRef } from 'react';
import { Box, Flex, Button, Input, VStack, useToast, Spinner, Text } from '@chakra-ui/react';
import axios from 'axios';
import ResultsDisplay from './ResultsDisplay';
import MealSummary from './MealSummary';
import TotalMeals from './TotalMeals';
import { useTableCount } from '../../contexts/TableCountContext';

const ImageUploader = ({ onReset }) => {
    const { resetTableCount } = useTableCount();
    const [image, setImage] = useState(null);
    const [results, setResults] = useState(null);
    const [mealsSummary, setMealsSummary] = useState({});
    const [validatedMeals, setValidatedMeals] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [fileName, setFileName] = useState('');
    const fileInputRef = useRef(null);  // Ajoutez cette ligne pour créer une référence
    const toast = useToast();

    // Fonction à appeler depuis le Navbar
    const resetStates = () => {
        setImage(null);
        setResults(null);
        setMealsSummary({});
        setValidatedMeals(null);
        setFileName('');  // Réinitialiser la valeur des inputs file
        resetTableCount();  // Réinitialiser le compteur
        if (fileInputRef.current) {
            fileInputRef.current.value = '';
        }
    };

    // Exposer la fonction de reset via le prop
    React.useEffect(() => {
        if (onReset) {
            onReset(resetStates);
        }
    }, [onReset]);

    // Fonction utilitaire pour traiter les cellules
    const clearNotSelectedCell = (cell) => {
        if (typeof cell === "string") {
            // Remplacer "NOT_SELECTED," par une chaîne vide
            const cleanedCell = cell.replace("NOT_SELECTED,", "").trim();

            // Si la cellule nettoyée contient un nombre, on la retourne
            const parsedNumber = parseFloat(cleanedCell);
            if (!isNaN(parsedNumber)) {
                return parsedNumber; // Retourner le nombre tel quel
            }

            // Retourner la cellule nettoyée même si elle ne contient pas de nombre
            return cleanedCell;
        }

        return cell; // Retourner la cellule telle quelle si ce n'est pas une chaîne
    };


    // Fonction pour cumuler les repas validés
    // ImageUploader.jsx
    const accumulateMeals = (newMeals) => {
        const updatedSummary = { ...mealsSummary };

        Object.keys(newMeals).forEach((date) => {
            if (!updatedSummary[date]) {
                updatedSummary[date] = {};
            }

            newMeals[date].forEach((meal) => {
                if (updatedSummary[date][meal.meal]) {
                    // Cumuler la quantité si le repas existe déjà
                    updatedSummary[date][meal.meal] += meal.quantity;
                } else {
                    // Ajouter un nouveau repas
                    updatedSummary[date][meal.meal] = meal.quantity;
                }
            });
        });

        setMealsSummary(updatedSummary);
    };


    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setImage(file);
            setFileName(file.name); // Mettre à jour le nom du fichier
        }
    };

    const organizeByDate = (tableData) => {
        const dates = tableData[0].filter((_, index) => index > 1 && index % 2 === 0); // Extraction des dates dans les colonnes
        const organizedData = {};

        // Itérer sur les lignes du tableau (en sautant la première ligne contenant les dates)
        for (let i = 1; i < tableData.length; i++) {
            const row = tableData[i];
            const mealType = row[0];   // Déjeuner ou Dîner
            const dishType = row[1];   // Entrée, Plat Principal, etc.

            // Itérer sur chaque date et récupérer le plat et la quantité associés
            for (let j = 2; j < row.length; j += 2) {
                const date = dates[(j - 2) / 2];  // Récupération de la date
                const dish = row[j];              // Nom du plat
                const quantity = row[j + 1];      // Quantité ou état sélectionné

                if (!organizedData[date]) {
                    organizedData[date] = [];  // Initialisation pour chaque date
                }

                // Ajout des informations dans la structure organisée
                organizedData[date].push({
                    mealType,
                    dishType,
                    dish,
                    quantity: parseFloat(quantity) || (quantity === 'SELECTED,' ? 1 : 0) // Conversion en quantité
                });
            }
        }

        return organizedData;
    };

    const handleSubmit = async () => {
        if (!image) {
            toast({
                title: "Erreur",
                description: "Veuillez fournir une image pour l'analyse.",
                status: "error",
                duration: 5000,
                isClosable: true,
            });
            return;
        }

        setIsLoading(true); // Commence à afficher le loader

        const formData = new FormData();
        formData.append('image', image);

        try {
            const response = await axios.post(
                'https://backend-ajscanrepas.onrender.com/analyze_document',
                formData,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                    maxContentLength: Infinity,
                    maxBodyLength: Infinity
                }
            );

            console.log('Réponse complète:', response);;

            const cleanedResults = response.data.tables.map((table) =>
                table.map((row) =>
                    row.map((cell) => clearNotSelectedCell(cell))
                )
            );

            setResults({ ...response.data, tables: cleanedResults });

            toast({
                title: "Succès",
                description: "L'analyse de l'image a été réalisée avec succès.",
                status: "success",
                duration: 3000,
                isClosable: true,
            });

        } catch (error) {
            console.error('Erreur complète:', error);
            console.error('Message d\'erreur:', error.message);
            console.error('Réponse du serveur:', error.response?.data);
            console.error('Statut:', error.response?.status);

        } finally {
            setIsLoading(false); // Arrêter l'affichage du loader
        }
    };




    // Fonction pour calculer les repas commandés
    const calculateMealSummary = (tableData) => {
        const mealSummary = {};

        tableData.forEach((table) => {
            table.forEach((row) => {
                for (let i = 1; i < row.length; i++) {
                    let cell = row[i];
                    let meal = row[i - 1];

                    // Si la cellule contient un nombre, on l'ajoute au récapitulatif
                    const quantity = parseInt(cell, 10); // Convertir la cellule en nombre
                    if (!isNaN(quantity)) {
                        if (mealSummary[meal]) {
                            mealSummary[meal] += quantity;
                        } else {
                            mealSummary[meal] = quantity;
                        }
                    }

                    // Calculer les repas sélectionnés
                    if (cell && cell.trim() === 'SELECTED,') {
                        if (mealSummary[meal]) {
                            mealSummary[meal] += 1;
                        } else {
                            mealSummary[meal] = 1;
                        }
                    }
                }
            });
        });

        return mealSummary;
    };

    const handleMealValidation = (validatedMeals) => {
        setValidatedMeals(validatedMeals);
        accumulateMeals(validatedMeals);
    };

    return (
        <Box bg="gray.100" p={{ base: '2', lg: "6" }} borderRadius="md" boxShadow="lg" w="100%" maxWidth={{ base: '95vw', lg: "90vw", xl: "100%" }} mx="auto">
            <VStack spacing={6}>
                <Flex
                    justifyContent="center"
                    alignItems="center"
                    mt={4}
                    mb={2}
                    spacing={4}
                    direction={{ base: "column", md: "row" }}
                    gap={{ base: "2", md: "4" }}
                >
                    {/* Input pour utiliser l'appareil photo */}
                    <Box
                        as="label"
                        htmlFor="camera-upload"
                        display="inline-block"
                        bg="blue.500"
                        color="white"
                        fontWeight="bold"
                        px={4}
                        py={2}
                        borderRadius="md"
                        cursor="pointer"
                        _hover={{ bg: "blue.600" }}
                        _focus={{ outline: "none", boxShadow: "outline" }}
                    >
                        Utiliser l'appareil photo
                        <Input
                            id="camera-upload"
                            type="file"
                            accept="image/*"
                            capture="environment"
                            onChange={handleFileChange}
                            display="none"
                            ref={fileInputRef}  // Ajoutez cette référence

                        />
                    </Box>

                    <Box
                        as="label"
                        htmlFor="file-upload"
                        display="inline-block"
                        bg="blue.500"
                        color="white"
                        fontWeight="bold"
                        mx={4}
                        px={4}
                        py={2}
                        borderRadius="md"
                        cursor="pointer"
                        _hover={{ bg: "blue.600" }}
                        _focus={{ outline: "none", boxShadow: "outline" }}
                    >
                        Choisir un fichier
                        <Input
                            id="file-upload"
                            type="file"
                            accept="image/*,application/pdf"  // Ajout du format PDF
                            onChange={handleFileChange}
                            display="none"
                            ref={fileInputRef}  // Ajoutez cette référence

                        />
                    </Box>
                </Flex>

                {/* Affichage du nom du fichier sélectionné */}
                {fileName && (
                    <Text fontSize="md" color="gray.600">
                        Fichier sélectionné : {fileName}
                    </Text>
                )}

                <Button colorScheme="teal" onClick={handleSubmit} isDisabled={!image}>
                    {isLoading ? <Spinner size="sm" /> : "Analyser"}
                </Button>

                {results && (
                    <>
                        <ResultsDisplay data={results.tables} />
                        <MealSummary data={results.tables} onValidate={handleMealValidation} />
                    </>
                )}

                <TotalMeals mealsSummary={mealsSummary}
                    data={results?.tables || []}
                />
            </VStack>
        </Box>
    );
};

export default ImageUploader;
